import { css } from 'styled-components';

const print = css`
  @media print {
   
    .select2-selection--single {
      border: none !important;
      background: #fff !important;
    }
    .digitisation__content--left {
      padding: 48px 24px;

      @media print {
        .react-tabs__tab-list {
          display: none !important;
        }

        .react-tabs__tab-panel{
          display: block !important;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
          background-color: white;
          color: #000;
          border: 1px solid #000;

          td {
            padding: 10px !important;
            width: auto;
            font-size: 14px;
          }

          tbody tr {
            border-top-color: #000;
            page-break-inside: avoid;
          }
        }
    }

    .policies-list {
      margin: 0 !important;
      padding: 0 !important;

      .title {
        margin-bottom: 6px !important;
      }
    }

    .digitisation__content {
      h2 h3,
      h4 {
        font-weight: 600;
      }

      h2 {
        color: #000 !important;
        font-size: 28px;
      }

      h3 {
        font-size: 16px;
        margin-bottom: 8px;
      }

      h4,
      p,
      li {
        font-size: 14px !important;
        color: #000;
      }
    }

    .select2-container,
    .scroll-top,
    .main-navbar,
    .municipality-name,
    .mobile-actions {
      display: none !important;
    }
  }

 
`;

export default print;
