export const municipalityMap = {
  amargadhi: 'amargadhi',
  bagmati: 'bagmati',
  bannigadhijayagadh: 'banni',
  barhabise: 'barabise',
  dhangadhi: 'dhangadhi',
  jayaprithivi: 'jayp',
  kailari: 'kailari',
  konjyosom: 'konjyosom',
  masta: 'masta',
  nawadurga: 'nabadurga',
  sanfebagar: 'sanfe',
  sunkoshi: 'sunkoshi',
};

export const labelMap = {
  municipality: 'group_rq4lr04/__006',
  gender: 'group_xm9ug59/__001',
  caste: 'group_xm9ug59/__005',
  arrival_time: 'group_vi5xd61/__014',
  janajati_type: 'group_xm9ug59/__012',
  purpose_of_visit: 'group_vi5xd61/__002',
  prior_information: 'group_vi5xd61/__016',
  employee_behavior: 'group_vi5xd61/__018',
  required_information_explanation: 'group_vi5xd61/__019',
  quality_of_service_process: 'group_vi5xd61/__021',
  use_of_technology: 'group_vi5xd61/__022',
  good_aspects_of_office: 'group_vi5xd61/_',
  bad_aspects_of_office: 'group_vi5xd61/__003',
};

export const valueMap = {
  municipality: {
    amargadhi: 'अमरगढी नगरपालिका',
    bagmati: 'बागमति गाउँपालिका',
    banni: 'बान्नीगढी जयगढ गाउँपालिका',
    barabise: 'बाह्रविसे नगरपालिका',
    dhangadhi: 'धनगढी उपमहानगरपालिका',
    jayp: 'जयपृथ्वी नगरपालिका',
    kailari: 'कैलारी गाउँपालिका',
    konjyosom: 'कोन्ज्योसोम गाउँपालिका',
    masta: 'मष्टा गाउँपालिका',
    nabadurga: 'नवदूर्गा गाउँपालिका',
    sanfe: 'साँफेबगर नगरपालिका',
    sunkoshi: 'सुनकाेशी गाउँपालिका',
  },
  gender: {
    female: 'महिला',
    male: 'पुरुष',
    other: 'अन्य',
  },
  caste: {
    'barahman/chhet': 'ब्राम्हण/क्षेत्री/ठकुरी',
    'adibashi/janja': 'आदिवासी/जनजाती',
    dalit: 'दलित',
    'alpasankik/lop': 'अल्पसंख्यक/लोपोन्मुख',
  },
  arrival_time: {
    less_than_half: 'आधा घण्टाभन्दा कम',
    '1hour': '१ घण्टा',
    '1-3hours': '१ - ३ घण्टा',
    '3-5hours': '३-५ घण्टा',
    more_than_5hou: '५ घण्टा भन्दा माथी',
  },
  purpose_of_visit: {
    meet: 'भेटघाट',
    inquire: 'सोधपुछ गर्न',
    regural_servic: 'नियमित सेवा',
    complaint: 'उजुरी/गुनासो राख्न',
    called_by_offi: 'कार्यालयले बोलाएर',
    other: 'अन्य',
  },
  prior_information: {
    well_informed: 'पुरै जानकारि थियो',
    little_informe: 'अलिकति जानकारि थियो',
    no_information: 'जानकारि थिएन',
  },
  employee_behavior: {
    friendly: 'मिलनसार र मैत्रीपूर्ण',
    good: 'ठीकै',
    bad: 'खराब',
    'don’t_want_tot': 'बताउन चाहान्न',
  },
  required_information_explanation: {
    all_clear: 'पुरै स्पष्ट गरिदिए',
    lees_clear: 'अलिकति स्पष्ट गरे',
    puzzled: 'अलमल्ल पारे',
  },
  quality_of_service_process: {
    easy_fast: 'सहज तथा छरितो',
    general: 'सामान्य',
    long_complex: 'लामो र झन्झटिलो',
  },
  use_of_technology: {
    good_use: 'राम्रो प्रयोग',
    general_use: 'सामान्य प्रयोग',
    no_use_atall: 'प्रयोग भएको छैन',
    'don’t_know': 'थाहा छैन',
  },
  good_aspects_of_office: {
    physical_condi: 'भौतिक अवस्था',
    nature_of_offi: 'कर्मचारिको व्यबहार',
    service_flow: 'सेवा प्रवाह',
    use_of_tech_in: 'सुचना प्रविधिको प्रयोग',
    'didn’t_like_an': 'कुनै पनि राम्रो लागेन',
    other: 'अन्य',
  },
  bad_aspects_of_office: {
    physical_condi: 'भौतिक अवस्था',
    nature_of_offi: 'कर्मचारिको व्यबहार',
    service_flow: 'सेवा प्रवाह',
    use_of_tech_in: 'सुचना प्रविधिको प्रयोग',
    other: 'अन्य',
  },
};
