import { css } from 'styled-components';
import { images} from '../../../config';

const sprites = css`
  .icon {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    background: url(${images.icons.dataSprites});
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;

    &__responsible_branch {
      background-position: 32px -168px;
    }

    &__responsible_branch_new {
      background-position: 0 -423px;
    }

    &__responsible_official {
      background-position: 32px 0;
    }

    &__service_charge {
      background-position: 32px -205px;
    }

    &__service_duration {
      background-position: 32px -86px;
    }

    &__room_number {
      background-position: 32px -42px;
    }

    &__contact_number {
      background-position: 0 -42px;
    }

    &__service_grievance_official {
      background-position: 0 -177px;
    }
    &__service_grievance_procedure {
      background-position: 0 -132px;
    }
    &__service_form_link {
      background-position: 0 -222px;
    }

    @media print {
      display: none;
    }
  }
`;

export default sprites;
