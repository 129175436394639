import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { municipalityMap, labelMap, valueMap } from './config/mapper';
import { get, isEmpty } from 'lodash';
import { ADToBS } from 'bikram-sambat-js';
import BarChart from './components/BarChart';
import ChartWrapper from './components/ChartWrapper';
import { Loader } from '../../components/Loader';
import PieChart from './components/PieChart';
import { englishToNepaliNumber } from 'nepali-number';

const extractFiscalYearAndQuarter = (date, isAD = true) => {
  if (isAD) {
    date = ADToBS(date.split('T')[0]);
  }

  let newDate = new Date(date);
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let quarter = month < 4 ? 3 : Math.floor(month / 4);
  let fiscalYear = month < 4 ? `${year - 1}-${year}` : `${year}-${year + 1}`;
  return {
    date: date,
    fiscal_year: fiscalYear,
    quarter: quarter,
  };
};

const reduceStat = (data, key) => {
  let stat = data.reduce((acc, current) => {
    const value = current[labelMap[key]];
    if (value) {
      if (valueMap.hasOwnProperty(key)) {
        const label = valueMap[key][value];
        acc[label] = acc[label] ? acc[label] + 1 : 1;
      } else {
        acc[value] = acc[value] ? acc[value] + 1 : 1;
      }
    }
    return acc;
  }, {});

  let initialStat = {};
  if (valueMap.hasOwnProperty(key)) {
    Object.keys(valueMap[key]).forEach(
      label => (initialStat[valueMap[key][label]] = 0)
    );
  }
  stat = { ...initialStat, ...stat };

  return Object.keys(stat).map(function(key) {
    return { key: key, value: stat[key] };
  });
};

const generateStats = data => {
  return {
    gender: reduceStat(data, 'gender'),
    caste: reduceStat(data, 'caste'),
    arrival_time: reduceStat(data, 'arrival_time'),
    janajati_type: reduceStat(data, 'janajati_type'),
    purpose_of_visit: reduceStat(data, 'purpose_of_visit'),
    prior_information: reduceStat(data, 'prior_information'),
    employee_behavior: reduceStat(data, 'employee_behavior'),
    required_information_explanation: reduceStat(
      data,
      'required_information_explanation'
    ),
    quality_of_service_process: reduceStat(data, 'quality_of_service_process'),
    use_of_technology: reduceStat(data, 'use_of_technology'),
    good_aspects_of_office: reduceStat(data, 'good_aspects_of_office'),
    bad_aspects_of_office: reduceStat(data, 'bad_aspects_of_office'),
  };
};

function MunicipalityExitPoll(props) {
  const [exitPollData, setExitPollData] = useState(null);
  const [filteredExitPollData, setFilteredExitPollData] = useState(null);
  const [exitPollStats, setExitPollStats] = useState(null);
  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);
  const [filters, setFilters] = useState({
    fiscal_year: null,
    quarter: 'all',
  });

  const url =
    'https://kc.kobo.yipl.com.np/susasan/forms/aDWP4sAmEZrZdEfQtd4VXD/api?query={"' +
    labelMap['municipality'] +
    '":"' +
    municipalityMap[props.subDomainData.title_eng] +
    '"}';

  const resetFilter = () => {
    setFilters({
      fiscal_year: fiscalYearOptions[fiscalYearOptions.length - 1],
      quarter: 'all',
    });
  };

  // Event Handlers
  const handleFiscalYearSelection = e => {
    setFilters({
      ...filters,
      fiscal_year: e.target.value,
    });
  };

  const handleQuarterSelection = quarter => {
    setFilters({
      ...filters,
      quarter: quarter,
    });
  };

  // Fetch Data from KOBO
  useLayoutEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      let json = await response.json();
      json = json.map(item => {
        let date = extractFiscalYearAndQuarter(item.start);
        return {
          ...item,
          ...date,
        };
      });
      const fiscalYears = [...new Set(json.map(item => item['fiscal_year']))];
      setExitPollData(json);
      setFilteredExitPollData(json);
      setFiscalYearOptions(fiscalYears);
      setFilters({
        fiscal_year: fiscalYears[fiscalYears.length - 1],
        quarter: 'all',
      });
    };
    fetchData();
  }, [url]);

  // Filter data when filters are changed
  useEffect(() => {
    if (isEmpty(exitPollData)) {
      return;
    }
    let data = exitPollData;
    if (filters.fiscal_year) {
      data = data.filter(item => item.fiscal_year === filters.fiscal_year);
    }
    if (filters.quarter !== 'all') {
      data = data.filter(item => item.quarter === filters.quarter);
    }
    const stats = generateStats(data);
    setFilteredExitPollData(data);
    setExitPollStats(stats);
  }, [exitPollData, filters]);

  // Render Component
  if (isEmpty(exitPollStats)) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <Content>
      <Helmet>
        <title>
          {' '}
          {props.subDomainData.title_eng} municipality exit poll |{' '}
          {props.subDomainData.title} {props.subDomainData.municipality} नागरिक
          बहिर्गमन पत्र
        </title>
        <link
          rel="canonical"
          href={`${window.location.protocol}//${props.subDomainData.title_eng}.susasan.org/municipality-exit-poll`}
        />
        <meta
          name="keywords"
          content={`${props.subDomainData.title} ${props.subDomainData.municipality} नागरिक बहिर्गमन पत्र, ${props.subDomainData.title_eng} municipality exit poll, municipality activities`}
        />
        <meta
          name="description"
          content={`${props.subDomainData.title} ${props.subDomainData.municipality} नागरिक बहिर्गमन पत्र, ${props.subDomainData.title_eng} municipality exit poll`}
        />
      </Helmet>
      <div className="container">
        {console.log(exitPollStats)}
        <TopHeader>
          <header className="">
            <h2>नागरिक बहिर्गमन पत्र</h2>
          </header>
          <Filter className="filters">
            <div className="filter-wrap">
              <div className="fiscalyear_filter">
                <h4>आर्थिक वर्ष छान्नुहाेस् </h4>
                <select
                  value={filters.fiscal_year}
                  onChange={handleFiscalYearSelection}
                >
                  <option
                    className={`${
                      filters.fiscal_year === 'all' ? 'active' : ''
                    }`}
                    value="all"
                  >
                    सबै
                  </option>
                  {fiscalYearOptions.length &&
                    fiscalYearOptions.map(fiscalYear => (
                      <option
                        className={`${
                          filters.fiscal_year === fiscalYear ? 'active' : ''
                        }`}
                        key={fiscalYear}
                        value={fiscalYear}
                      >
                        {englishToNepaliNumber(fiscalYear)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="quarter_filter">
                <h4>चाैमासिक छान्नुहाेस्</h4>
                <ul>
                  <li
                    className={`${filters.quarter === 'all' ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection('all')}
                  >
                    सबै
                  </li>
                  <li
                    className={`${filters.quarter === 1 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(1)}
                  >
                    पहिलाे
                  </li>
                  <li
                    className={`${filters.quarter === 2 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(2)}
                  >
                    दाेस्राे
                  </li>
                  <li
                    className={`${filters.quarter === 3 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(3)}
                  >
                    तेस्राे
                  </li>
                </ul>
              </div>
            </div>
            <div className="action-buttons">
              <a onClick={resetFilter} className="reset">
                रिसेट
              </a>
            </div>
          </Filter>
        </TopHeader>
        <Main className="main">
          <div className="stats-wrapper">
            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.purpose_of_visit}
              keys={{
                x_axis: 'कार्यालयमा देहायको कुन कामको लागि आउनु भएको हो ?',
              }}
              keyMapper={valueMap.purpose_of_visit}
              axisRotationValue="-45"
            />
            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.arrival_time}
              keys={{
                x_axis: 'कार्यालय सम्म आइपुग्न तपाई लाई कति समय लाग्यो ?',
              }}
              keyMapper={valueMap.arrival_time}
            />

            <ChartWrapper
              chartType={PieChart}
              data={exitPollStats.prior_information}
              keys={{
                x_axis:
                  'तपाईको कामका लागि  को सँग सम्पर्क गर्ने  के प्रक्रिया पुरा गर्ने भन्नेमा तपाईलाई कतिको जानकारी थियो  ?',
              }}
              keyMapper={valueMap.prior_information}
            />

            <ChartWrapper
              chartType={PieChart}
              data={exitPollStats.employee_behavior}
              keys={{
                x_axis:
                  'तपाई प्रति सेवाप्रदायक कर्मचारीको  व्यवहार कस्तो रहयो ?',
              }}
              keyMapper={valueMap.employee_behavior}
            />

            <ChartWrapper
              chartType={PieChart}
              data={exitPollStats.gender}
              keys={{ x_axis: 'सेवाग्राहीको लिंग' }}
              keyMapper={valueMap.gender}
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.caste}
              keys={{ x_axis: 'सेवाग्राहीको जातजाति' }}
              keyMapper={valueMap.caste}
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.use_of_technology}
              keys={{
                x_axis: 'कार्यालयमा सूचना प्रविधिको प्रयोग कस्तो पाउँनु भयो ?',
              }}
              keyMapper={valueMap.use_of_technology}
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.good_aspects_of_office}
              keys={{
                x_axis: 'तपाईलाई कार्यालयको कुन—कुन पक्ष धेरै राम्रो लाग्यो ?',
              }}
              keyMapper={valueMap.good_aspects_of_office}
              axisRotationValue="-45"
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.bad_aspects_of_office}
              keys={{
                x_axis:
                  'तपाईले कार्यालयको कुन—कुन पक्षलाई सुधार गर्नुपर्ने देख्नु भयो ?',
              }}
              keyMapper={valueMap.bad_aspects_of_office}
              axisRotationValue="-45"
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.required_information_explanation}
              keys={{
                x_axis:
                  'तपाईको कामकालागि आवश्यक सूचनाहरु (प्रमाण—कागजात र शुल्क—दस्तुर) बारे तपाईलाई सेवाप्रदायक कर्मचारीले  कतिको स्पष्ट गरे  ?',
              }}
              keyMapper={valueMap.required_information_explanation}
            />

            <ChartWrapper
              chartType={BarChart}
              data={exitPollStats.quality_of_service_process}
              keys={{
                x_axis:
                  'सेवा प्राप्त गर्न अपनाइएको प्रक्रिया के— कस्तो लाग्यो ?',
              }}
              keyMapper={valueMap.quality_of_service_process}
            />
          </div>
        </Main>
      </div>
    </Content>
  );
}

const Content = styled.div`
  background-image: radial-gradient(circle at 51% 2%, #008acb, #00529b);
  min-height: 100vh;
  padding-bottom: 24px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
  }
`;

const TopHeader = styled.div`
  padding: 24px 0;
  color: #fff;

  h2 {
    margin-bottom: 24px;
  }

  .action-buttons {
    padding-top: 22px;
  }

  .fiscalyear_filter,
  .quarter_filter {
    margin-right: 24px;

    select {
      background: rgba(1, 73, 131, 0.4);
      color: #f0f8ff;
      font-size: 1rem;
      line-height: 30px;
      padding-left: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 18px;
      height: 30px;
      padding-right: 14px;
      outline: none;
      cursor: pointer;
      margin-top: 12px;
      > option {
        background: white;
        color: black;
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    li {
      border: 1px solid #e8e8e8;
      font-size: 14px;
      padding: 2px 12px;
      border-radius: 30px;
      margin-right: 10px;
      color: #fff;
      cursor: pointer;

      &.active {
        border-color: #023d71;
        background: #023d71;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  .filter-wrap {
    display: flex;
    align-items: center;
  }

  .reset {
    color: #f0f8ff;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 20px;
    display: inline-block;
    padding: 6px 24px;
    border: 1px solid #f0f8ff;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #023d71;
      border-color: #023d71;
    }
  }
`;

const Main = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .stats-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -16px 40px;

    .amcharts-amexport-menu-level-0.amcharts-amexport-top {
      top: 0;
    }

    .chart__inner {
      width: 100%;
      padding: 0 16px;
      margin-bottom: 24px;
      box-sizing: border-box;

      h2 {
        font-size: 18px;
        margin: 0 24px 24px;
        font-weight: 700;
        color: #333333;
        padding-right: 100px;

        + div > div {
          position: static !important;
        }
      }

      @media only screen and (min-width: 768px) {
        width: 50%;
        flex: 0 0 auto;
        flex-basis: 50%;
        max-width: 50%;
      }
    }

    .tabs-wrapper {
      background: #fefefe;
      padding: 16px;
      height: 95%;

      .table-wrap {
        padding-top: 30px;
      }
    }

    .react-tabs {
      position: relative;
    }

    .chart-icon {
      margin-right: 8px;

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .react-tabs__tab-list {
      position: absolute;
      top: 0;
      right: 50px;
      font-size: 12px;
      display: flex;
    }

    .react-tabs__tab {
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }

      &.react-tabs__tab--selected {
        opacity: 1;
      }
    }
  }
`;
export default MunicipalityExitPoll;
