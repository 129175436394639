import React, { lazy, PureComponent, Suspense } from 'react';
import 'babel-polyfill';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import subDomain from './app/helper/subDomain';
import Helper from './app/helper/Helper';
import { Navbar, Spinner } from '../src/app/components';
import BrowserRouter from 'react-g-analytics/dist/BrowserRouter';
import styled from 'styled-components';
import CitizenReportCard from './app/screens/citizenReportCard/CitizenReportCard';
import MunicipalityExitPoll from './app/screens/MunicipalityExitPoll/MunicipalityExitPoll';

window.styled = styled;

const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ './app/screens/home/home')
);
const Eprofile = lazy(() =>
  import(/* webpackChunkName: "eprofile" */ './app/screens/eprofile/eprofile')
);
const Digitisation = lazy(() =>
  import(
    /* webpackChunkName: "digitisation" */ './app/screens/digitisation/digitisation'
  )
);
const EprofileMapSection = lazy(() =>
  import(
    /* webpackChunkName: "eprofilemapsection" */ './app/screens/eprofile/map/eprofile-map'
  )
);
const Digitisationinner = lazy(() =>
  import(
    /* webpackChunkName: "digitisationinner" */ './app/screens/digitisation/digitisation-inner/digitisation-inner'
  )
);
const MunicipalityVoice = lazy(() =>
  import(
    /* webpackChunkName: "municipalityvoice" */ './app/screens/municipality-voice/municipality-voice'
  )
);
const CitizenCharter = lazy(() =>
  import(
    /* webpackChunkName: "citizencharter" */ './app/screens/citizenCharter/citizenCharter'
  )
);
const CovidInfo = lazy(() =>
  import(/* webpackChunkName: "covidinfo" */ './app/screens/covid/CovidInfo')
);
const NoMatch = lazy(() =>
  import(/* webpackChunkName: "nomatch" */ './app/screens/404')
);

let hosts = window.location.host.split('.');
let host = hosts[0];

//if url ko first word is eprofile then get 2nd word e.g. eprofile.mellekhmun.gov.np
//else first word e.g. bagmati.susasan.org
if (hosts[0] === 'eprofile') {
  let munName = hosts[1];
  munName = munName.slice(0, -3);
  host = munName;
}

const subDomainData = subDomain[host] || subDomain.mellekhmun;

class RouterComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      showError:
        window.location.pathname.includes('mun-voice') &&
        window.location.pathname.split('').length > 11,
      invalidDomain: false,
    };
  }

  componentDidMount() {
    document.title = `${subDomainData.title}: Official digital portal for ${subDomainData.title_eng} `;
  }

  checkDomain() {
    if (
      !hosts.includes('staging') &&
      (hosts.length === 3 || hosts.length === 4)
    ) {
      return Helper.localGovernment().indexOf(host) !== -1;
    } else if (
      hosts.includes('staging') &&
      (hosts.length === 4 || hosts.length === 5)
    ) {
      return Helper.localGovernment().indexOf(host) !== -1;
    } else if (
      hosts.includes('localhost:3000') ||
      hosts.includes('localhost:5000')
    ) {
      return true;
    } else {
      host = '!domain';
      this.setState({
        invalidDomain: true,
      });
      return false;
    }
  }

  render() {
    let googleAnalyticsCode = subDomainData.analyticsCode || 'UA-113618013-2';
    googleAnalyticsCode =
      host[1] === 'staging' ? 'UA-113618013-4' : googleAnalyticsCode;

    const isEprofile = hosts[0] === 'eprofile';

    if (isEprofile) {
      return (
        <BrowserRouter id={googleAnalyticsCode}>
          {this.checkDomain() ? (
            <Router>
              <div className="main-app">
                <Navbar subDomainData={subDomainData} />
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <Home {...props} subDomainData={subDomainData} />
                      )}
                    />
                    <Route
                      exact
                      path="/eprofile"
                      render={props => (
                        <Eprofile {...props} subDomainData={subDomainData} />
                      )}
                    />
                    <Route
                      exact
                      path="/mun-detail"
                      render={props => (
                        <Digitisation
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/mun-detail/:id"
                      render={props => (
                        <Digitisationinner
                          {...props}
                          subDomainData={subDomainData}
                          showError={this.state.showError}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/poi"
                      render={props => (
                        <EprofileMapSection
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/mun-voice/:id"
                      render={props => (
                        <MunicipalityVoice
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/mun-voice"
                      render={props => (
                        <MunicipalityVoice
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/covid19"
                      render={props => (
                        <CovidInfo {...props} subDomainData={subDomainData} />
                      )}
                    />

                    <Route
                      path="/crc"
                      render={props => (
                        <CitizenReportCard
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/municipality-exit-poll"
                      render={props => (
                        <MunicipalityExitPoll
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/badapatra"
                      render={props => (
                        <CitizenCharter
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      render={() => (
                        <NoMatch
                          filteredGovernment={Helper.filteredGovernment(
                            host[0]
                          )}
                          noRoutes={true}
                          showError={this.state.showError}
                        />
                      )}
                    />
                  </Switch>
                </Suspense>
              </div>
            </Router>
          ) : (
            <Router>
              <Suspense fallback={<Spinner />}>
                <Route
                  component={() => (
                    <NoMatch
                      filteredGovernment={Helper.filteredGovernment(host)}
                      noWildCard={true}
                      invalidDomain={this.state.invalidDomain}
                      showError={this.state.showError}
                    />
                  )}
                />
              </Suspense>
            </Router>
          )}
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter id={googleAnalyticsCode}>
          {this.checkDomain() ? (
            <Router>
              <div className="main-app">
                <Navbar subDomainData={subDomainData} />
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <Home {...props} subDomainData={subDomainData} />
                      )}
                    />
                    <Route
                      exact
                      path="/eprofile"
                      render={props => (
                        <Eprofile {...props} subDomainData={subDomainData} />
                      )}
                    />
                    <Route
                      exact
                      path="/mun-detail"
                      render={props => (
                        <Digitisation
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/mun-detail/:id"
                      render={props => (
                        <Digitisationinner
                          {...props}
                          subDomainData={subDomainData}
                          showError={this.state.showError}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/eprofile/poi"
                      render={props => (
                        <EprofileMapSection
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/mun-voice/:id"
                      render={props => (
                        <MunicipalityVoice
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/mun-voice"
                      render={props => (
                        <MunicipalityVoice
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/covid19"
                      render={props => (
                        <CovidInfo {...props} subDomainData={subDomainData} />
                      )}
                    />

                    <Route
                      path="/crc"
                      render={props => (
                        <CitizenReportCard
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/municipality-exit-poll"
                      render={props => (
                        <MunicipalityExitPoll
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      path="/badapatra"
                      render={props => (
                        <CitizenCharter
                          {...props}
                          subDomainData={subDomainData}
                        />
                      )}
                    />

                    <Route
                      render={() => (
                        <NoMatch
                          filteredGovernment={Helper.filteredGovernment(
                            host[0]
                          )}
                          noRoutes={true}
                          showError={this.state.showError}
                        />
                      )}
                    />
                  </Switch>
                </Suspense>
              </div>
            </Router>
          ) : (
            <Router>
              <Suspense fallback={<Spinner />}>
                <Route
                  component={() => (
                    <NoMatch
                      filteredGovernment={Helper.filteredGovernment(host)}
                      noWildCard={true}
                      invalidDomain={this.state.invalidDomain}
                      showError={this.state.showError}
                    />
                  )}
                />
              </Suspense>
            </Router>
          )}
        </BrowserRouter>
      );
    }
  }
}

export default RouterComponent;
