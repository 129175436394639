export const labelMap = {
  ward: 'group_qt09y69/group_cq56h51/__046',
  department: '__004',
  ward_effectiveness: 'group_qt09y69/group_cq56h51/__047',
  physical_infrastructure: 'group_qt09y69/group_cq56h51/__048',
  flow_of_public_services: 'group_qt09y69/group_cq56h51/__049',
  regular_services: 'group_qt09y69/group_cq56h51/__050',
  special_arrangements: 'group_qt09y69/group_cq56h51/__051',
  service_recipients_treatment: 'group_qt09y69/group_cq56h51/__052',
  information_on_public_importance: 'group_qt09y69/group_cq56h51/__053',
  ward_office_service_quality: 'group_qt09y69/group_cq56h51/__054',
  ward_office_communication: 'group_qt09y69/group_cq56h51/__055',
  hearings: 'group_qt09y69/group_cq56h51/__056',
};

export const valueMap = {
  ward_effectiveness: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },
  department: {
    _: 'पालीका',
    __: 'वडा कार्यालय',
    ___1: 'स्वास्थ्य चौकी/अस्पताल',
    ___2: 'विद्यालय (शिक्षा)',
  },
  physical_infrastructure: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },

  flow_of_public_services: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },

  regular_services: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },
  special_arrangements: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },

  service_recipients_treatment: {
    ____: 'पूर्ण सन्तुष्ट छु',
    _____1: 'आंशिक संन्तुष्ट छु',
    __: 'असन्तुष्ट छु',
    ___1: 'भन्न चाहन्न',
  },

  information_on_public_importance: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },

  ward_office_service_quality: {
    __: 'धेरै संवेदनशिल',
    ___1: 'सामान्य संवेदनशिल',
    _: 'निरपेक्ष',
    ___2: 'थाहा छैन',
  },

  ward_office_communication: {
    _: 'छ',
    __1: 'छैन',
    __: 'थहा छैन',
  },

  hearings: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },
};
export const xAxis = {
  ward: 'वडा न',
  ward_effectiveness:
    'वडा स्तरमा योजना तर्जुमा, कार्यान्वयन र अनुगमन कार्य कत्तिको प्रभावकारी पाउनु भएको छ?',
  physical_infrastructure:
    'वडा भित्रका योजना तथा भौतिक पूर्वाधारको संरक्षण, मर्मतसंभार, रेखदेख र ब्यवश्थापनकार्य कत्तिको प्रभावकारी पाउनु भएको छ?',
  flow_of_public_services:
    'वडा कार्यालयवाट सार्वजनिक सेवा एवं विकासकार्यहरुको ब्यवस्थापन र प्रवाह कत्तिकोे प्रभावकारी पाउनु भएको छ?',
  regular_services:
    'वडा कार्यालयले प्रदान गर्ने नियमित सेवाहरु (जस्तै ब्यक्तिगत घटना दर्ता, प्रमाणीकरण, शिफारिस आदि) कत्तिको प्रभावकारी पाउनु भएको छ?',
  special_arrangements:
    'वडाकार्यालयले प्रदान गर्ने महिला, वालवालीका, जेष्ठनागरिक, अपाङ्गता भएका ब्यक्तिहरु, विपन्न, अल्पसंख्यक तथा सिमान्तकृत समुदाय लक्षित सेवाहरु कत्तिको प्रभावकारी पाउनु भएको छ?',
  service_recipients_treatment:
    'वडा कार्यालयले सेवाग्राही नागरिक सँग गर्ने ब्यवहार प्रति कत्तिको सन्तुष्ट हुनुहुन्छ?',
  information_on_public_importance:
    'वडा कार्यालयले सार्वजनिक महत्वका सूचनाहरु (नीति, कार्यक्रम, बजेट, स्थानिय कार्यविधिहरु, निर्णहरु आदि) नागरिकहरुले सहज रुपमा प्राप्त गर्न सक्ने गरि गरेको ब्यवश्था कत्तिको प्रभावकारी पाउनु भएको छ?',
  ward_office_service_quality:
    'वडा कार्यालय सेवाको गुणस्तर, नागरिक सहभागीता, गुनासो सुनुवाई, नीति तथा विधिको पालना, ढिलासुस्तीको न्यूनिकरण, सुशासन र जवाफदेहिता प्रवद्र्धन प्रती कत्तिको संवेदनशिल पाउनु भएको छ?',
  ward_office_communication:
    'वडा कार्यालयले सार्वजनिक सरोकारकाविषयमा नागरिकहरुसंग संवाद वा अन्तरक्रिया गर्नेगरेको छ?',
  hearings:
    'वडा कार्यालयले आयोजनागर्ने सार्वजनिक सुनुवाई, सामाजिक परिक्षण, सामाजिक लेखाजोखा आदि कार्यक्रमहरुमा कत्तीको प्रभावकारी पाउनु भएको छ?',
};
