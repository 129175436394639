/* Imports */
import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const PieChart = ({ data, keys, keyMapper, sheet, colors }) => {
  const piechartDiv = useRef(null);

  useLayoutEffect(() => {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    am4core.options.queue = true;
    // Themes end
    // am4core.options.onlyShowOnViewport = true;
    // Create chart instance
    let chart = am4core.create(piechartDiv.current, am4charts.PieChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'pdfdata', label: 'PDF' },
            ],
          },
          {
            label: 'Print',
            type: 'print',
          },
        ],
      },
    ];
    chart.exporting.filePrefix = keys.x_axis;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'key';

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(30);

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right';

    pieSeries.colors.list = colors.map(color => {
      return am4core.color(color);
    });

    chart.data = data;

    return () => {
      chart.dispose();

      chart = null;
    };
  }, [colors, data, keys.x_axis]);

  return <div ref={piechartDiv} style={{ width: '100%', height: '400px' }} />;
};

export default PieChart;
