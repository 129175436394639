import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

class FontChanger extends Component {
  constructor() {
    super();
    this.state = {
      baseSize: 14.4,
      changedSize: 14.4,
    };
    this.resetFontSize = this.resetFontSize.bind(this);
    this.increaseFontSize = this.increaseFontSize.bind(this);
    this.decreaseFontSize = this.decreaseFontSize.bind(this);
  }

  resetFontSize() {
    this.setState(
      {
        changedSize: this.state.baseSize,
      },
      () => {
        document.getElementsByTagName('html')[0].style.fontSize = `${
          this.state.changedSize
        }px`;
      }
    );
  }

  increaseFontSize() {
    this.setState(
      {
        changedSize: this.state.changedSize + 1,
      },
      () => {
        document.getElementsByTagName('html')[0].style.fontSize = `${
          this.state.changedSize
        }px`;
      }
    );
  }

  decreaseFontSize() {
    if (this.state.changedSize > 14) {
      this.setState(
        {
          changedSize: this.state.changedSize - 1,
        },
        () => {
          document.getElementsByTagName('html')[0].style.fontSize = `${
            this.state.changedSize
          }px`;
        }
      );
    }
  }

  render() {
    return (
      <FontChangeList className="font-changer">
        <li onClick={this.decreaseFontSize}>-</li>
        <li onClick={this.resetFontSize}>क</li>
        <li onClick={this.increaseFontSize}>+</li>
      </FontChangeList>
    );
  }
}
export default withRouter(FontChanger);

const FontChangeList = styled.ul`
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 60px;
  display: inline-flex;
  padding: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }

  > li {
    box-sizing: border-box;
    color: #fff;
    opacity: 0.6;
    font-size: 14px;
    padding: 0 6px;
    width: 33.43%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;

    &:hover {
      background-color: rgba(49, 149, 231, 0.9);
      opacity: 1;
    }

    &:nth-child(1) {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:nth-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      font-size: 12px;
    }

    &:nth-child(3) {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  @media print {
    display: none;
  }
`;
