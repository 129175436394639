import React, { useEffect, useState } from 'react';
import { labelMap, valueMap, xAxis } from '../config/data-maps/health';
import ChartWrapper from '../../MunicipalityExitPoll/components/ChartWrapper';
import BarChart from '../../MunicipalityExitPoll/components/BarChart';
import { Loader } from '../../../components/Loader';
import PieChart from '../../MunicipalityExitPoll/components/PieChart';

const reduceStat = (data, key) => {
  let stat = data.reduce((acc, current) => {
    const value = current[labelMap[key]];
    if (value) {
      if (valueMap.hasOwnProperty(key)) {
        const label = valueMap[key][value];
        acc[label] = acc[label] ? acc[label] + 1 : 1;
      } else {
        acc[value] = acc[value] ? acc[value] + 1 : 1;
      }
    }
    return acc;
  }, {});

  let initialStat = {};
  if (valueMap.hasOwnProperty(key)) {
    Object.keys(valueMap[key]).forEach(
      label => (initialStat[valueMap[key][label]] = 0)
    );
  }
  stat = { ...initialStat, ...stat };

  return Object.keys(stat).map(function(key) {
    return { key: key, value: stat[key] };
  });
};

const generateStats = data => {
  return {
    name: reduceStat(data, 'name'),
    department: reduceStat(data, 'department'),
    minimum_infrastructure: reduceStat(data, 'minimum_infrastructure'),
    immunization: reduceStat(data, 'immunization'),
    nutrition_program: reduceStat(data, 'nutrition_program'),
    family_planning_service: reduceStat(data, 'family_planning_service'),
    effective_regular_checkups: reduceStat(data, 'effective_regular_checkups'),
    free_medicine_information: reduceStat(data, 'free_medicine_information'),
    patients_treatment: reduceStat(data, 'patients_treatment'),
    requests_respone: reduceStat(data, 'requests_respone'),
  };
};

const HealthPostCharts = ({ data }) => {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    const generatedStat = generateStats(data);
    setStats(generatedStat);
  }, [data]);

  if (!stats) {
    return (
      <div className="stats-wrapper">
        <Loader />
      </div>
    );
  }
  if (stats.department[2].value === 0) {
    return (
      <div className="content__empty" style={{ marginTop: '20px' }}>
        <p>No Data was found.</p>
      </div>
    );
  }

  return (
    <div className="stats-wrapper">
      <ChartWrapper
        chartType={BarChart}
        data={stats.name}
        keys={{ x_axis: xAxis.name }}
        keyMapper={valueMap.name}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.minimum_infrastructure}
        keys={{ x_axis: xAxis.minimum_infrastructure }}
        keyMapper={valueMap.minimum_infrastructure}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.immunization}
        keys={{ x_axis: xAxis.immunization }}
        keyMapper={valueMap.immunization}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.nutrition_program}
        keys={{ x_axis: xAxis.nutrition_program }}
        keyMapper={valueMap.nutrition_program}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.family_planning_service}
        keys={{ x_axis: xAxis.family_planning_service }}
        keyMapper={valueMap.family_planning_service}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.effective_regular_checkups}
        keys={{ x_axis: xAxis.effective_regular_checkups }}
        keyMapper={valueMap.effective_regular_checkups}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.free_medicine_information}
        keys={{ x_axis: xAxis.free_medicine_information }}
        keyMapper={valueMap.free_medicine_information}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.patients_treatment}
        keys={{ x_axis: xAxis.patients_treatment }}
        keyMapper={valueMap.patients_treatment}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.requests_respone}
        keys={{ x_axis: xAxis.requests_respone }}
        keyMapper={valueMap.requests_respone}
      />
    </div>
  );
};

export default HealthPostCharts;
