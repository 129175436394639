import Colors from './colors';
import defaults from './defaults';

const { primary, sec, neutral } = Colors;
const { fontFamily, fontSize, fontWeight, lineHeight, transition } = defaults;

const theme = {
  primary,
  sec,
  neutral,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  transition,
};

export default theme;
