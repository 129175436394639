import Api from './api';

class ContactService {

    async saveContact(url,selectedMun,params) {
        try {
            const res = await Api.post(`${url}/api/municipality/${selectedMun}/contact`, params);
            return res;
        } catch (error) {
            return error;
        }
    }

}

export default new ContactService();
