import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DateImg from '../../../../../images/icons/date.svg';
import ImageClose from '../../../../../assets/images/ic_close.svg';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class DateChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderClose = this.renderClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.dateStart && !nextProps.dateEnd) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  }

  handleReset() {
    this.setState(
      {
        startDate: null,
        endDate: null,
      },
      () => {
        let startDate = null;
        let endDate = null;
        this.props.onDateChange({ startDate, endDate }, 'date');
      }
    );
  }

  handleChange = ({ startDate, endDate }) => {
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        let startDate;
        if (this.state.startDate) {
          startDate = moment(this.state.startDate).format('YYYY-MM-DD') || null;
        }

        let endDate;
        if (this.state.endDate) {
          endDate = moment(this.state.endDate).format('YYYY-MM-DD') || null;
        }

        this.props.onDateChange({ startDate, endDate }, 'date');
      }
    );
  };

  renderClose() {
    if (this.state.startDate && this.state.endDate) {
      return <CloseButton onClick={this.handleReset} />;
    }
  }

  render() {
    return (
      <Content>
        <DateRangePicker
          startDatePlaceholderText="सुरु मिति"
          endDatePlaceholderText="सम्पन्न मिति"
          orientation={window.innerWidth < 767 ? 'vertical' : 'horizontal'}
          verticalHeight={372}
          horizontalMargin={0}
          numberOfMonths={window.innerWidth < 767 ? 1 : 2}
          block={window.innerWidth < 767}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.handleChange({ startDate, endDate })
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          isOutsideRange={() => false}
        />

        {this.renderClose()}
      </Content>
    );
  }
}

export default DateChooser;

const Content = styled.div`
  position: relative;
  .DateRangePickerInput {
    background-color: transparent;
    border: none;
    display: flex;
    .DateInput:first-of-type {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;

      input {
        background-image: url(${DateImg});
        background-repeat: no-repeat;
        background-position: 20px center;
        padding-left: 50px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
    }

    .DateInput:last-of-type {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;

      input {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }
  }

  .DateInput {
    width: 132px;
    overflow: hidden;

    input {
      padding: 11px 12px 11px 8px;
      font-size: 1rem;
    }
  }
  .DateRangePickerInput_arrow {
    padding: 11px 0;
    background-color: #fff;
  }

  .DateInput_input__focused {
    border: 0;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #0087ca;
    border: 1px solid #0087ca;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background: #0087ca;
    border: 1px solid #0087ca;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover,
  .CalendarDay__hovered_span:hover {
    background: #0477b0;
    border: 1px solid #0477b0;
    color: #fff;
  }
  @media only screen and (max-width: 767px) {
    .DateRangePicker_picker {
      width: 290px;
      transform: none;
      .DayPicker {
        > div > div {
          width: 290px !important;
          .DayPicker_weekHeader {
            padding: 0;
            margin-left: -136px !important;
            top: 35px;
          }
        }
      }
      .CalendarMonthGrid__vertical {
        margin: 0;
        width: 100% !important;
        .CalendarMonth {
          padding: 0;
          .CalendarMonth_caption {
            padding-top: 5px;
          }
          table tbody tr td {
            padding: 10px;
          }
        }
      }
    }
  }
`;

const CloseButton = styled.span`
  background: url(${ImageClose}) no-repeat right;
  cursor: pointer;
  display: inline-block;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
`;
