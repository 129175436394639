/* Imports */
import React from 'react';
import TableChart from './TableChart';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const ChartWrapper = ({
  chartType,
  data,
  keys,
  keyMapper,
  axisRotationValue,
  pieColorsData,
  barColorData,
}) => {
  const { x_axis, y_axis } = keys;

  const chartData = data;

  const barColorValue = barColorData || '#4a90e2';

  const colors = pieColorsData || ['#5ab4ac', '#998ec3'];

  const axisRotation = axisRotationValue || false;

  const Chart = chartType;

  return (
    <div className="chart__inner">
      <div className="tabs-wrapper">
        <Tabs>
          <TabList>
            <Tab>
              <span className="chart-icon">
                <img src="/img/icons/ic_chart.svg" alt="Chart Icon" />
              </span>
            </Tab>
            <Tab>
              <span className="chart-icon">
                <img src="/img/icons/ic_table-chart.svg" alt="Chart Icon" />
              </span>
            </Tab>
          </TabList>
          <TabPanel>
            <div>
              <h2>{x_axis}</h2>
              <Chart
                data={chartData}
                keys={{ x_axis: x_axis, y_axis: y_axis }}
                barColorValue={barColorValue}
                colors={colors}
                axisRotation={axisRotation}
                keyMapper={keyMapper}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <TableChart x_axis={x_axis} y_axis={y_axis} data={chartData} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default ChartWrapper;
