export const labelMap = {
  name: 'group_qw1gt93/__082',
  department: '__004',
  public_information: 'group_qw1gt93/__083',
  minimum_infrastructure: 'group_qw1gt93/group_ol7xy65/__084',
  learning_activities: 'group_qw1gt93/group_gm1bm71/__090',
  consultation: 'group_qw1gt93/group_gm1bm71/__092',
  physical_mental_moral_development: 'group_qw1gt93/group_gm1bm71/__093',
  scholarships_information: 'group_qw1gt93/group_yw4fi65/__098',
  teachers_response: 'group_qw1gt93/group_cl0bw30/__102',
};

export const valueMap = {
  public_information: {
    __: 'धेरै सहज',
    ___1: 'आंशिक सहज',
    ___2: 'सहज छैन',
    ___3: 'थाहा छैन',
  },
  department: {
    _: 'पालीका',
    __: 'वडा कार्यालय',
    ___1: 'स्वास्थ्य चौकी/अस्पताल',
    ___2: 'विद्यालय (शिक्षा)',
  },
  minimum_infrastructure: {
    _: 'छ',
    __1: 'छैन',
    __: 'थाहा छैन',
  },
  learning_activities: {
    _: 'छ',
    __1: 'छैन',
    __: 'थाहा छैन',
  },
  consultation: {
    _: 'छ',
    __1: 'छैन',
    __: 'थाहा छैन',
  },
  physical_mental_moral_development: {
    _: 'पाउँछु',
    __1: 'पाउँदिन',
    __: 'थाहा छैन',
  },
  scholarships_information: {
    ____: 'सजिलै प्राप्त हुन्छ',
    _____1: 'सजिलै प्राप्त हुन्न',
    __: 'थाहा छैन',
  },
  teachers_response: {
    __: 'ध्यान दिन्छन',
    ___1: 'बेवास्ता गर्छन',
    _: 'झर्किन्छन',
    ___2: 'भन्न चाहन्न',
  },
};

export const xAxis = {
  name: 'विद्यालयको नाम',
  public_information:
    'विद्यालयवाट प्रदान गरिने सेवाहरुको वारेमा आवश्यक सार्वजनिक जानकारी कत्तिको सहज रुपमा प्राप्त हुने गरेको छ?',
  minimum_infrastructure:
    'विद्यालयले सेवा प्रदान गर्न आवश्यक न्यूनतम पूर्वाधार (भवन, कक्षाकोठा, खेल मैदान, फर्निचर,पुस्तकालय, उपकरण, प्रयोगशाला, खाजा घर, शौचालय, पिउने पानी आदि) को ब्यवश्थापन गरेको छ?',
  learning_activities:
    'के शिक्षक शिक्षिकाहरुले गर्ने शिक्षक सिकाई क्रियाकलापहरु रचनात्मक, विद्यार्थि केन्द्रित र सिकाईको उद्देश्य अनुरुप हुने गरेको छ?',
  consultation:
    'के शिक्षण सिकाईको विषयमा विद्यालयले विद्यार्थि तथा अभिभावकहरु सँग नियमित परामर्श गर्नेगरेको छ?',
  physical_mental_moral_development:
    'के विद्यालयले नियमित शिक्षण सिकाईका अतिरिक्त विद्यार्थिहरुको शारिरिक, मानसिक तथा नैतिक विकासमा पर्याप्त ध्यान दिएको पाउनुहुन्छ?',
  scholarships_information:
    'के छात्रा तथा दलित विद्यार्थिहरुकालागी प्रदान गरिने छात्रबृत्तिका वारेमा आवश्यक सुचना वा जानकारी (छात्रबृत्तिको रकम, प्रदान गरिने समय आदि) सहजै प्राप्त हुन्छ?',
  teachers_response:
    'विद्यार्थि वा अभिभावकहरुले विद्यालय सँग कुनै जानकारी मागे, सुझाव दिए वा गुनासो गरे के शिक्षकहरुले त्यसलाई कसरी लिन्छन?',
};
