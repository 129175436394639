import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { ADToBS } from 'bikram-sambat-js';
import { labelMap, municipalityMap } from './config/mapper';
import { isEmpty } from 'lodash';
import HealthPostCharts from './partials/HealthPostCharts';
import MunicipalityCharts from './partials/MunicipalityCharts';
import { englishToNepaliNumber } from 'nepali-number';
import { Loader } from '../../components/Loader';
import SchoolCharts from './partials/SchoolCharts';
import WardCharts from './partials/WardCharts';
import Tabs from './../../components/tabs/Tabs';

const extractFiscalYearAndQuarter = (date, isAD = true) => {
  if (isAD) {
    date = ADToBS(date.split('T')[0]);
  }

  let newDate = new Date(date);
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let quarter = month < 4 ? 3 : Math.floor(month / 4);
  let fiscalYear = month < 4 ? `${year - 1}-${year}` : `${year}-${year + 1}`;
  return {
    date: date,
    fiscal_year: fiscalYear,
    quarter: quarter,
  };
};

function CitizenReportCard(props) {
  const [crcData, setCrcData] = useState(null);
  const [filteredcrcData, setFilteredcrcData] = useState(null);
  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);
  const [filters, setFilters] = useState({
    fiscal_year: null,
    quarter: 'all',
  });

  const url = `https://kc.kobo.yipl.com.np/susasan/forms/aQpz6vjUJxZ7FMAWkbavAA/api?query={"${
    labelMap['municipality']
  }":"${municipalityMap[props.subDomainData.title_eng]}"}`;
  // Event Handlers

  const resetFilter = () => {
    setFilters({
      fiscal_year: fiscalYearOptions[fiscalYearOptions.length - 1],
      quarter: 'all',
    });
  };

  const handleFiscalYearSelection = e => {
    setFilters({
      ...filters,
      fiscal_year: e.target.value,
    });
  };

  const handleQuarterSelection = quarter => {
    setFilters({
      ...filters,
      quarter: quarter,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      let json = await response.json();
      json = json.map(item => {
        let date = extractFiscalYearAndQuarter(item.start);
        return {
          ...item,
          ...date,
        };
      });
      const fiscalYears = [...new Set(json.map(item => item['fiscal_year']))];
      setCrcData([...json]);
      setFilteredcrcData([...json]);
      setFiscalYearOptions(fiscalYears);
      setFilters({
        fiscal_year: fiscalYears[fiscalYears.length - 1],
        quarter: 'all',
      });
    };
    fetchData();
  }, [url]);

  // Filter data when filters are changed
  useEffect(() => {
    if (isEmpty(crcData)) {
      return;
    }
    let data = crcData;
    if (filters.fiscal_year) {
      data = data.filter(item => item.fiscal_year === filters.fiscal_year);
    }
    if (filters.quarter !== 'all') {
      data = data.filter(item => item.quarter === filters.quarter);
    }
    setFilteredcrcData(data);
  }, [crcData, filters]);

  if (isEmpty(filteredcrcData)) {
    return (
      <NoDataFoundText className="no-data-found-text">
        <p>No Data Found</p>
      </NoDataFoundText>
    );
  }

  return (
    <Content>
      <Helmet>
        <title>
          {' '}
          {props.subDomainData.title_eng} citizen report card |{' '}
          {props.subDomainData.title} {props.subDomainData.municipality} नागरिक
          लब्धांकपत्र
        </title>
        <link
          rel="canonical"
          href={`${window.location.protocol}//${props.subDomainData.title_eng}.susasan.org/crc`}
        />
        <meta
          name="keywords"
          content={`${props.subDomainData.title} ${props.subDomainData.municipality} नागरिक लब्धांकपत्र, ${props.subDomainData.title_eng} citizens report card, municipality activities`}
        />
        <meta
          name="description"
          content={`${props.subDomainData.title} ${props.subDomainData.municipality} नागरिक लब्धांकपत्र, ${props.subDomainData.title_eng} citizens report card`}
        />
      </Helmet>
      <div className="container">
        <TopHeader>
          <header className="">
            <h2>नागरिक लब्धांकपत्र</h2>
          </header>
          <Filter className="filters">
            <div className="filter-wrap">
              <div className="fiscalyear_filter">
                <h4>आर्थिक वर्ष छान्नुहाेस् </h4>
                <select
                  value={filters.fiscal_year}
                  onChange={handleFiscalYearSelection}
                >
                  <option
                    className={`${
                      filters.fiscal_year === 'all' ? 'active' : ''
                    }`}
                    value="all"
                  >
                    सबै
                  </option>
                  {fiscalYearOptions.length &&
                    fiscalYearOptions.map(fiscalYear => (
                      <option
                        className={`${
                          filters.fiscal_year === fiscalYear ? 'active' : ''
                        }`}
                        key={fiscalYear}
                        value={fiscalYear}
                      >
                        {englishToNepaliNumber(fiscalYear)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="quarter_filter">
                <h4>चाैमासिक छान्नुहाेस्</h4>
                <ul>
                  <li
                    className={`${filters.quarter === 'all' ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection('all')}
                  >
                    सबै
                  </li>
                  <li
                    className={`${filters.quarter === 1 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(1)}
                  >
                    पहिलाे
                  </li>
                  <li
                    className={`${filters.quarter === 2 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(2)}
                  >
                    दाेस्राे
                  </li>
                  <li
                    className={`${filters.quarter === 3 ? 'active' : ''}`}
                    onClick={() => handleQuarterSelection(3)}
                  >
                    तेस्राे
                  </li>
                </ul>
              </div>
            </div>
            <div className="action-buttons">
              <a onClick={resetFilter} className="reset">
                रिसेट
              </a>
            </div>
          </Filter>
        </TopHeader>
        <Main className="main">
          <Tabs>
            <MunicipalityCharts data={filteredcrcData} title="पालीका" />
            <WardCharts data={filteredcrcData} title="वडा कार्यालय" />
            <HealthPostCharts
              data={filteredcrcData}
              title="स्वास्थ्य चौकी/अस्पताल"
            />
            <SchoolCharts data={filteredcrcData} title="विद्यालय (शिक्षा) " />
          </Tabs>
        </Main>
      </div>
    </Content>
  );
}

const NoDataFoundText = styled.div`
  padding: 0 16px;
  background-image: radial-gradient(circle at 51% 2%, #008acb, #00529b);
  min-height: calc(100vh - 60px);
  color: #fff;

  p {
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 50px;
    font-size: 28px;
  }
`;

const Content = styled.div`
  background-image: radial-gradient(circle at 51% 2%, #008acb, #00529b);
  min-height: 100vh;
  padding-bottom: 24px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
  }
`;

const TopHeader = styled.div`
  padding: 24px 0;
  color: #fff;

  h2 {
    margin-bottom: 24px;
  }

  .action-buttons {
    padding-top: 22px;
  }

  .fiscalyear_filter,
  .quarter_filter {
    margin-right: 24px;
    select {
      background: rgba(1, 73, 131, 0.4);
      color: #f0f8ff;
      font-size: 1rem;
      line-height: 30px;
      padding-left: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 18px;
      height: 30px;
      padding-right: 14px;
      outline: none;
      cursor: pointer;
      margin-top: 12px;
      > option {
        background: white;
        color: black;
      }
    }
    ul {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    li {
      border: 1px solid #e8e8e8;
      font-size: 14px;
      padding: 2px 12px;
      border-radius: 30px;
      margin-right: 10px;
      color: #fff;
      cursor: pointer;

      &.active {
        border-color: #023d71;
        background: #023d71;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  .filter-wrap {
    display: flex;
    align-items: center;
  }

  .reset {
    color: #f0f8ff;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 20px;
    display: inline-block;
    padding: 6px 24px;
    border: 1px solid #f0f8ff;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #023d71;
      border-color: #023d71;
    }
  }
`;

const Main = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .tabs-container {
    .tab {
      padding: 16px 20px;
      display: inline-block;
      margin-right: 4px;
      border-radius: 4px 4px 0 0;
      background: #fff;
      border-bottom: 1px solid #ccc;

      &:hover,
      &:focus {
        background: #e8e8e8;
        cursor: pointer;
      }

      &.tab-active {
        background: #023d71;
        color: #fff;
      }
    }
  }

  .stats-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -16px 40px;

    .amcharts-amexport-menu-level-0.amcharts-amexport-top {
      top: 0;
    }

    .chart__inner {
      width: 100%;
      padding: 0 16px;
      margin-bottom: 24px;
      box-sizing: border-box;

      h2 {
        font-size: 18px;
        margin: 0 24px 24px;
        font-weight: 700;
        color: #333333;
        padding-right: 100px;

        + div > div {
          position: static !important;
        }
      }

      @media only screen and (min-width: 768px) {
        width: 50%;
        flex: 0 0 auto;
        flex-basis: 50%;
        max-width: 50%;
      }
    }

    .tabs-wrapper {
      background: #fefefe;
      padding: 16px;
      height: 95%;

      .table-wrap {
        padding-top: 30px;
      }
    }

    .react-tabs {
      position: relative;
    }

    .chart-icon {
      margin-right: 8px;

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .react-tabs__tab-list {
      position: absolute;
      top: 0;
      right: 50px;
      font-size: 12px;
      display: flex;
    }

    .react-tabs__tab {
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }

      &.react-tabs__tab--selected {
        opacity: 1;
      }
    }
  }
`;
export default CitizenReportCard;
