export const municipalityMap = {
  amargadhi: 'amargadhi',
  bagmati: 'bagmati',
  bannigadhijayagadh: 'banni',
  barhabise: 'barabise',
  dhangadhi: 'dhangadhi',
  jayaprithivi: 'jayp',
  kailari: 'kailari',
  konjyosom: 'konjyosom',
  masta: 'masta',
  nawadurga: 'navadurga',
  sanfebagar: 'sanfe',
  sunkoshi: 'sunkoshi',
};

export const labelMap = {
  municipality: 'group_ih56a06/__008',
  निकाय: '__004',
  लिङ्ग: 'group_ih56a06/_',
  जातजाति: 'group_ih56a06/__001',
  'वडा न': 'group_ih56a06/__009',
  'जनजाती भए उल्लेख गर्नुहोस्': 'group_ih56a06/__010',
  शिक्षा: 'group_ih56a06/__002',
};

export const valueMap = {
  municipality: {
    amargadhi: 'अमरगढी नगरपालिका',
    bagmati: 'बागमति गाउँपालिका',
    banni: 'बान्नीगढी जयगढ गाउँपालिका',
    barabise: 'बाह्रविसे नगरपालिका',
    dhangadhi: 'धनगढी उपमहानगरपालिका',
    jayp: 'जयपृथ्वी नगरपालिका',
    kailari: 'कैलारी गाउँपालिका',
    konjyosom: 'कोन्ज्योसोम गाउँपालिका',
    masta: 'मष्टा गाउँपालिका',
    nabadurga: 'नवदूर्गा गाउँपालिका',
    sanfe: 'साँफेबगर नगरपालिका',
    sunkoshi: 'सुनकाेशी गाउँपालिका',
  },
  लिङ्ग: {
    female: 'महिला',
    male: 'पुरुष',
    other: 'अन्य',
  },
  जातजाति: {
    'barahman/chhet': 'ब्राम्हण/ क्षेत्री/ ठकुरी',
    'adibashi/janaj': 'आदिवासी/ जनजाती',
    dalit: 'दलित',
    'alpasankik/lop': 'अल्पसंख्यक/ लोपोन्मुख',
  },
  निकाय: {
    _: 'पालीका',
    __: 'वडा कार्यालय',
    ___1: 'स्वास्थ्य चौकी/अस्पताल',
    ___2: 'विद्यालय (शिक्षा)',
  },
};
