/* Imports */
import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const BarChart = ({
  data,
  keys,
  keyMapper,
  sheet,
  barColorValue,
  axisRotation,
}) => {
  const barchartDiv = useRef(null);

  useLayoutEffect(() => {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.options.queue = true;
    // am4core.options.onlyShowOnViewport = true;
    // Create chart instance
    let chart = am4core.create(barchartDiv.current, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'pdfdata', label: 'PDF' },
            ],
          },
          {
            label: 'Print',
            type: 'print',
          },
        ],
      },
    ];
    chart.exporting.filePrefix = keys.x_axis;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'key';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.rotation = axisRotation;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'key';
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series.columns.template.fillOpacity = 0.8;
    series.columns.template.fill = am4core.color(barColorValue);

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 1;

    chart.data = data;

    return () => {
      chart.dispose();

      chart = null;
    };
  }, [axisRotation, barColorValue, data, keys.x_axis]);

  return <div ref={barchartDiv} style={{ width: '100%', height: '400px' }} />;
};

export default BarChart;
