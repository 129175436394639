import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';
import ContactService from '../../services/contactService';
import { Flex, Box } from 'grid-styled';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { rgba } from '../../utils';
import Mail from '../../../assets/images/mail.svg';
import onClickOutside from 'react-onclickoutside';
var Recaptcha = require('react-recaptcha');

// create a variable to store the component instance
let recaptchaInstance;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false,
      contact: {
        name: '',
        email: '',
        message: '',
      },
      errMsg: {
        name: '',
        email: '',
        message: '',
      },
      showToast: false,
      toastType: '',
      cursor: 'not-allowed',
      buttonDisabledStatus: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.onRecaptchaResponse = this.onRecaptchaResponse.bind(this);
  }

  handleClick(e) {
    const { onToggleContact } = this.props;
    onToggleContact();

    this.setState({
      condition: !this.state.condition,
    });
    if (!this.state.condition) {
      this.setState({
        contact: {
          name: '',
          email: '',
          message: '',
        },
      });
    }
    this.changeRecaptchaText();
    e.stopPropagation();
  }

  handleOnChange(e) {
    let newContact = this.state.contact;
    const name = e.target.name;
    newContact[name] = e.target.value;

    this.setState({
      contact: newContact,
    });
  }

  validate() {
    let isError = false;
    const errors = {
      name: '',
      email: '',
      message: '',
    };

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.contact.email
      )
    ) {
      isError = true;
      errors.email = 'इमेल भर्नुहोस्';
    }

    if (!this.state.contact.name.length) {
      isError = true;
      errors.name = 'नाम भर्नुहोस्';
    }

    if (!this.state.contact.message.length) {
      isError = true;
      errors.message = 'सन्देश भर्नुहोस्';
    }

    this.setState({
      errMsg: errors,
    });

    return isError;
  }

  handleFormSubmit() {
    const error = this.validate();

    if (!error) {
      ContactService.saveContact(
        this.props.url,
        this.props.selectedMun,
        this.state.contact
      ).then(response => {
        if (response.status === 200) {
          toast.success('Message sent', {
            position: toast.POSITION.TOP_RIGHT,
          });

          this.setState({
            condition: !this.state.condition,
            contact: {
              name: '',
              email: '',
              message: '',
            },
          });
        } else {
          toast.error('Error occured', {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      });
      recaptchaInstance.reset();
    }
  }

  callback() {}

  onRecaptchaResponse(response) {
    if (response) {
      this.setState({
        cursor: 'pointer',
        buttonDisabledStatus: false,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.condition !== this.state.condition) {
      this.setState({
        condition: nextProps.condition,
      });
    }
  }

  handleClickOutside = () => {
    if (this.state.condition) {
      this.setState({
        condition: false,
      });
    }
  };

  changeRecaptchaText() {
    document
      .getElementById('g-recaptcha')
      .insertAdjacentHTML('afterbegin', '<div id="new_label"></div>');
    let newLabel = document.getElementById('new_label');
    newLabel.innerText = 'म यो सन्देश पठाउन चाहन्छु ।';
    let style = newLabel.style;
    style.position = 'absolute';
    style.top = '28px';
    style.left = '53px';
    style.backgroundColor = '#f9f9f9';
    style.fontSize = '12px';
  }

  render() {
    return (
      <Content selectedMun={this.props.selectedMun}>
        <ul>
          <li className={this.state.condition ? 'contact_dropdown_open' : ''}>
            <span
              className="contact_form_close_button"
              onClick={this.handleClickOutside}
            >
              x
            </span>
            <span
              className={this.state.condition ? 'button toggled' : 'button'}
              onClick={this.handleClick}
            >
              {window.innerWidth > 767 ? (
                <Fragment>
                  {' '}
                  सम्पर्क <img src={Mail} alt="mail" />{' '}
                </Fragment>
              ) : (
                <img src={Mail} alt="mail" />
              )}
            </span>
            <div className="contact__dropdown">
              {this.props.contact && (
                <div className="contact-top__wrap">
                  {/* <span className="label">सम्पर्कका लागिः</span> */}
                  <h2>{this.props.contact.name}</h2>
                  <Flex>
                    <Box width={0.65}>
                      <address>
                        {this.props.title}
                        {this.props.contact.ward_no &&
                          `, वडा नं. ${this.props.contact.ward_no}`}
                      </address>
                    </Box>
                    {this.props.contact.post_box_no && (
                      <Box width={0.35}>
                        <span className="label">पोस्ट बक्स नं. </span>
                        <span>{this.props.contact.post_box_no} </span>
                      </Box>
                    )}
                  </Flex>
                  <Flex>
                    {this.props.contact.phn_no && (
                      <Box width={0.65}>
                        <span className="label">फोन : </span>
                        <span>{this.props.contact.phn_no} </span>
                      </Box>
                    )}
                    {this.props.contact.fax_no && (
                      <Box width={0.35}>
                        <span className="label">फ्याक्स : </span>
                        <span>{this.props.contact.fax_no} </span>
                      </Box>
                    )}
                  </Flex>
                  <Flex>
                    {this.props.contact.email.length !== 0 && (
                      <Box width={1}>
                        <div className="label">
                          <span>इमेल : </span>
                        </div>
                        <div className="label-desc">
                          {this.props.contact.email.map(email => (
                            <a href={`mailto:${email}`} key={email}>
                              {email}
                            </a>
                          ))}
                        </div>
                      </Box>
                    )}
                  </Flex>
                </div>
              )}

              <form
                className="contact--form"
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleFormSubmit();
                }}
              >
                <p>
                  यस तथ्यांक पाेर्टलमा तपाईका कुनै प्रतिकृया भएमा तल उल्लेख
                  गरिदिनुहाेलाः
                </p>

                <div className="error__wrap">
                  <ul>
                    {this.state.errMsg.name && (
                      <li>{this.state.errMsg.name}</li>
                    )}
                    {this.state.errMsg.email && (
                      <li>{this.state.errMsg.email}</li>
                    )}
                    {this.state.errMsg.message && (
                      <li>{this.state.errMsg.message}</li>
                    )}
                  </ul>
                </div>
                <div className="form__wrap">
                  <div className="form-group form-group--half">
                    <label>नाम</label>
                    <input
                      type="text"
                      name="name"
                      value={this.state.contact.name}
                      onChange={this.handleOnChange}
                    />
                  </div>
                  <div className="form-group form-group--half">
                    <label>ईमेल</label>
                    <input
                      type="email"
                      name="email"
                      value={this.state.contact.email}
                      onChange={this.handleOnChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>सन्देश</label>
                    <textarea
                      name="message"
                      value={this.state.contact.message}
                      onChange={this.handleOnChange}
                      // required
                    />
                  </div>
                  <Recaptcha
                    ref={e => (recaptchaInstance = e)}
                    sitekey="6LdYgkkUAAAAAMSYPEm2OmsYCE9wmML2CxeB67cW"
                    render="explicit"
                    verifyCallback={this.onRecaptchaResponse}
                    onloadCallback={this.callback}
                  />
                  <div className="form-group action">
                    <button
                      type="submit"
                      className="btn"
                      style={{ cursor: `${this.state.cursor}` }}
                      disabled={this.state.buttonDisabledStatus}
                    >
                      सम्पर्क गर्नुहोस्
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
        </ul>
        <ToastContainer hideProgressBar={true} />
      </Content>
    );
  }
}

export default withRouter(onClickOutside(Contact));

const Content = styled.div`
  > ul > li {
    margin-left: 6px;
    position: relative;
    .button {
      color: ${props => rgba(props.theme.neutral.white, 0.6)};
      cursor: pointer;
      font-size: 1rem;
      text-decoration: none;
      transition: all 0.2s ease-in;

      img {
        height: 10px;
        opacity: 0.6;
      }

      &:hover {
        color: ${props => props.theme.neutral.white};

        img {
          opacity: 1;
        }
      }
    }
    .contact_form_close_button {
      display: none;
    }
    &.contact_dropdown_open .contact_form_close_button {
      z-index: 1010;
      display: inline-block;
      position: absolute;
      cursor: pointer;
      text-indent: -99999px;
      top: 57px;
      right: -21px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      &:before,
      &:after {
        position: absolute;
        left: 19px;
        top: 7px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #e0e0e0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  .contact__dropdown {
    display: none;
    background: #fff;
    border-radius: 14px;
    display: none;
    position: absolute;
    right: -36px;
    top: 36px;
    width: 547px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px;
    &:before {
      border-bottom: 12px solid #028eff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: '';
      position: absolute;
      right: 40px;
      top: -11px;
    }
    p {
      text-align: left;
    }
  }
  .contact-top__wrap {
    background: #028eff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #f0f8ff;
    padding: 22px 28px;
    > div {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & + p {
      margin: 0;
      padding: 24px 30px 0;
    }
    div.label {
      width: 45px;
      float: left;
      opacity: 0.7;
    }

    span.label {
      opacity: 0.7;
    }
    .label-desc {
      width: calc(100% - 45px);
      float: left;

      a {
        font-size: 1rem;
        color: #fff;
        display: inline-block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          content: ',';
          display: inline-block;
          margin-left: 1px;
          margin-right: 5px;
          color: #fff;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 2px;
    }
    address {
      font-size: 1.0714285714285714rem;
    }
    dl {
      dt {
        color: rgba(240, 248, 255, 0.5);
        font-size: 1rem;
      }
      dd {
        font-size: 1.1428571428571428rem;
      }
    }
  }

  .contact--form {
    padding: 22px 30px 48px;
    text-align: center;
    textarea {
      margin-bottom: 32px;
    }
    .action {
      margin-top: 40px;
    }
  }

  .toggled + .contact__dropdown {
    display: block;
  }

  @media screen and (max-width: 830px) {
    > ul > li {
      position: static;

      &.contact_dropdown_open .contact_form_close_button {
        top: 83px;
        right: 17px;
      }
    }

    .contact__dropdown {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0;
      top: 60px;
      max-height: calc(100vh - 60px);
      overflow-y: scroll;
      &:before {
        content: none;
      }

      .contact-top__wrap {
        border-radius: 0;
      }

      .form-group--half {
        width: 100%;

        &:first-of-type + .form-group--half {
          margin-left: 0;
      }
    }

    .contact--form .btn{
      width: 80%;
    }
  }

  @media screen and (max-width: 480px) {
    #g-recaptcha {
      transform: scale(0.8);
      transform-origin: 0;
    }
  }
`;
