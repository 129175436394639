import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchImg from '../../../../../images/icons/search.png';
import debounce from '../../../../utils/debounce';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };

    this.onSearchChange = debounce(this.props.onSearchChange, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.search) {
      this.textInput.value = '';
    }
  }

  onChange = e => {
    this.setState({ query: e.target.value }, () => {
      this.onSearchChange(this.state.query, 'search');
    });
  };

  render() {
    return (
      <SearchBox>
        <input
          ref={el => {
            this.textInput = el;
          }}
          className="search-input"
          type="search"
          placeholder="भिडियो खोज्नुहोस्"
          value={this.state.query}
          onChange={this.onChange}
        />
      </SearchBox>
    );
  }
}

Search.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

export default Search;

const SearchBox = styled.div`
  .search-input {
    background-image: url(${SearchImg});
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 16px;
    width: 265px;
    height: 50px;
    border-radius: 24px;
    background-color: #ffffff;
    border: solid 1px #028eff;
    color: #000e1a;
    font-size: 1rem;
    line-height: 22px;
    outline: none;
    -webkit-appearance: textfield;
    text-indent: 47px;
    &::placeholder {
      color: #000e1a;
      opacity: 0.5;
    }

    @media screena and (min-width: 768px) {
      width: 385px;
    }
  }

  .close-btn {
    margin: -9px -35px;
    cursor: pointer;
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border: 0.05em solid #00529b;
    font-size: 3em;
    border-radius: 50%;
    position: relative;
    background-color: white;
    &:before {
      content: '';
      display: block;
      position: absolute;
      background-color: #00529b;
      width: 80%;
      height: 6%;
      left: 10%;
      top: 47%;
      transform: rotate(225deg);
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: #00529b;
      width: 6%;
      height: 80%;
      left: 47%;
      top: 10%;
      background-color: $color;
      transform: rotate(225deg);
    }
  }
`;
