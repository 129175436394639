export const labelMap = {
  gender: 'group_ih56a06/_',
  department: '__004',
  municipality_satisfaction: 'group_yt1kk95/group_gf49m31/__014',
  special_arrangements: 'group_ka4eh27/__022',
  services_provided: 'group_yc9bn80/__023',
  intermediaries_support: 'group_yc9bn80/__025',
  public_services_procedure: 'group_va7ck02/__030',
  citizen_charter_usefulness: 'group_xy6ow79/__033',
  required_information_explanation: 'group_xy6ow79/__034',
  interaction_on_public_concern: 'group_xo3kc89/__041',
  use_of_technology: 'group_ak8wd06/group_ak8wd06',
};

export const valueMap = {
  gender: {
    female: 'महिला',
    male: 'पुरुष',
    other: 'अन्य',
  },
  department: {
    _: 'पालीका',
    __: 'वडा कार्यालय',
    ___1: 'स्वास्थ्य चौकी/अस्पताल',
    ___2: 'विद्यालय (शिक्षा)',
  },
  municipality_satisfaction: {
    full_satisfied: 'पूर्ण सन्तुष्ट छु',
    partial_satisf: 'आंशिक संन्तुष्ट छु',
    not_satisfied: 'असन्तुष्ट छु',
    dontwantto_say: 'भन्न चाहन्न',
  },
  special_arrangements: {
    know: 'थाहा छ',
    dont_know: 'थाहा छैन',
    dontwantto_say: 'भन्न चाहन्न',
  },
  services_provided: {
    full_knowledge: 'पूर्ण जानकार छु',
    less_knowledge: 'आंशिक जानकार छु',
    no_knowledge: 'खाषै जानकार छैन',
  },
  intermediaries_support: {
    yes: 'छ',
    no: 'छैन',
    dontwantto_say: 'भन्न चाहन्छ',
  },
  public_services_procedure: {
    has_info: 'जानकारी छ',
    no_info: 'जानकारी छैन',
    dont_know: 'कार्यविधि हुन्छ भन्ने थाहा छैन',
  },
  citizen_charter_usefulness: {
    ____: 'धेरै उपयोगी छ',
    _____1: 'आंशिक उपयोगी छ',
    __: 'उपयोगी छैन',
    ___1: 'थाहा छैन',
  },
  required_information_explanation: {
    __: 'धेरै सहयोग',
    ___1: 'आंशिक सहयोग',
    ___2: 'सहयोग गर्दैनन',
    ___3: 'थाहा छैन',
  },
  interaction_on_public_concern: {
    _: 'छ',
    __1: 'छैन',
    __: 'थहा छैन',
  },
  use_of_technology: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },
};
