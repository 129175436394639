import React, { Component } from 'react';
import { ShareButtons } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareIcon from '../../../assets/images/share.svg';
import SocialIcons from '../../../assets/images/ic_sprite_top.svg';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

const { FacebookShareButton, TwitterShareButton } = ShareButtons;

class SocialMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      toggleShare: false,
    };
    this.toggleShare = this.toggleShare.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.value = `${window.location.origin}${window.location.pathname}`;
  }

  onCopy = () => {
    this.setState({ copied: true, toggleShare: false }, () => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 1500);
    });
  };

  toggleShare() {
    this.setState({ toggleShare: !this.state.toggleShare });
  }

  handleClickOutside = () => {
    if (this.state.toggleShare) {
      this.setState({
        toggleShare: false,
      });
    }
  };

  render() {
    const location = window.location.pathname;
    const page =
      (location !== '/' &&
        location
          .replace('/', '-')
          .split('')[1]
          .toUpperCase() + location.substr(2).replace('/', '-')) ||
      '';
    return (
      <MobileShare className="share-dropdown--mobile">
        <a onClick={this.toggleShare}>
          <span>शेयर</span>
          <img src={ShareIcon} alt="share" />
        </a>
        <Socials className={this.state.toggleShare ? 'show' : 'hide'}>
          <FacebookShareButton
            url={this.value}
            quote={`${document.getElementsByTagName('title')[0].text} ${page}`}
            className="Demo__some-network__share-button facebook"
          />
          <TwitterShareButton
            url={this.value}
            title={`${document.getElementsByTagName('title')[0].text} ${page}`}
            className="Demo__some-network__share-button twitter"
          />
          <CopyToClipboard onCopy={this.onCopy} text={this.value}>
            <span className="website">website</span>
          </CopyToClipboard>
        </Socials>
        {this.state.copied && <span className="copied-message">Copied</span>}
      </MobileShare>
    );
  }
}

export default onClickOutside(SocialMobile);

const MobileShare = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;

  a {
    opacity: 0.6;
    transition: opacity 0.2s ease-in;
    span {
      color: #fff;
      font-size: 1rem;
      margin-right: 8px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    img {
      width: 14px;
      filter: brightness(0) invert(1);
    }

    &:hover {
      opacity: 1;
    }
  }

  span,
  img {
    display: inline-block;
    vertical-align: middle;
  }
  .copied-message {
    position: absolute;
    background-color: ${props => props.theme.primary.raju};
    color: ${props => props.theme.primary.adhyaaro};
    font-size: ${props => props.theme.fontSize.xxs}px;
    padding: 0 2px;
    border-radius: 2px;
    left: 0;
    top: 60px;
  }
`;

const Socials = styled.div`
      display:none;
      margin-top:10px;
      width:122px;
      padding:0;
      height:40px;
      border-radius:3px;
      position:absolute;
      left:50%;
      transform: translateX(-50%);
      background:#f0f8ff;
      &.show{
        display:flex;
        pointer-events: auto;
        box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px 0px;
        >div{
          width:33%;
          height:100%;
        }
        .facebook, .twitter,.website{
          box-sizing: border-box;
          &:hover{
                filter:none;
            }
        }
        .facebook{
            background: url(${SocialIcons}) 0 -2px no-repeat;
            background-size:100%;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            
        }
        .twitter{
                background: url(${SocialIcons}) 0 -55px no-repeat;
                background-size:100%;
                -webkit-filter: grayscale(1);
                filter: grayscale(1);
                border-left: 1px solid #b2b6ba;
                border-right: 1px solid #b2b6ba;
        }
        .website{
                background: url(${SocialIcons}) 0 -117px no-repeat;
                background-size:100%;
                -webkit-filter: grayscale(1);
                filter: grayscale(1);
                display:inline-block;
                text-indent:-999999px;
                height:100%;
                width:33%;
        }
        &:before{
                position:absolute;
                content:'';
                left:50%;
                transform: translateX(-50%);
                top:-7px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 7px solid #f0f8ff;
            }
        }
      }   

     
`;
