import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import modal from './modal';
import sprites from './sprites';
import base from './base';
import print from './print';

const GlobalStyle = createGlobalStyle`
    ${reset};
    ${base};
    ${sprites};
    ${print};
    ${modal};
`;

export default GlobalStyle;
