import { englishToNepaliNumber } from 'nepali-number';
import React, { useEffect, useState } from 'react';
import {
  labelMap,
  valueMap,
  xAxis,
} from '../../citizenReportCard/config/data-maps/ward';
import { Loader } from '../../../components/Loader';
import BarChart from '../../MunicipalityExitPoll/components/BarChart';
import ChartWrapper from '../../MunicipalityExitPoll/components/ChartWrapper';

const reduceStat = (data, key) => {
  let stat = data.reduce((acc, current) => {
    let value = current[labelMap[key]];

    if (key === 'ward') {
      value = 'वडा न. ' + englishToNepaliNumber(current[labelMap[key]]);
    }
    if (value) {
      if (valueMap.hasOwnProperty(key)) {
        const label = valueMap[key][value];
        acc[label] = acc[label] ? acc[label] + 1 : 1;
      } else {
        acc[value] = acc[value] ? acc[value] + 1 : 1;
      }
    }
    return acc;
  }, {});

  let initialStat = {};
  if (valueMap.hasOwnProperty(key)) {
    Object.keys(valueMap[key]).forEach(
      label => (initialStat[valueMap[key][label]] = 0)
    );
  }
  stat = { ...initialStat, ...stat };

  return Object.keys(stat).map(function(key) {
    return { key: key, value: stat[key] };
  });
};

const generateStats = data => {
  return {
    ward: reduceStat(data, 'ward'),
    department: reduceStat(data, 'department'),
    ward_effectiveness: reduceStat(data, 'ward_effectiveness'),
    physical_infrastructure: reduceStat(data, 'physical_infrastructure'),
    flow_of_public_services: reduceStat(data, 'flow_of_public_services'),
    regular_services: reduceStat(data, 'regular_services'),
    special_arrangements: reduceStat(data, 'special_arrangements'),
    service_recipients_treatment: reduceStat(
      data,
      'service_recipients_treatment'
    ),
    information_on_public_importance: reduceStat(
      data,
      'information_on_public_importance'
    ),
    ward_office_service_quality: reduceStat(
      data,
      'ward_office_service_quality'
    ),
    ward_office_communication: reduceStat(data, 'ward_office_communication'),
    hearings: reduceStat(data, 'hearings'),
  };
};

const WardCharts = ({ data }) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const generatedStat = generateStats(data);
    setStats(generatedStat);
  }, [data]);

  if (!stats) {
    return (
      <div className="stats-wrapper">
        <Loader />
      </div>
    );
  }

  if (stats.department[1].value === 0) {
    return (
      <div className="content__empty" style={{ marginTop: '20px' }}>
        <p>No Data was found.</p>
      </div>
    );
  }
  return (
    <div className="stats-wrapper">
      <ChartWrapper
        chartType={BarChart}
        data={stats.ward}
        keys={{ x_axis: xAxis.ward }}
        keyMapper={valueMap.ward}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.ward_effectiveness}
        keys={{ x_axis: xAxis.ward_effectiveness }}
        keyMapper={valueMap.ward_effectiveness}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.physical_infrastructure}
        keys={{ x_axis: xAxis.physical_infrastructure }}
        keyMapper={valueMap.physical_infrastructure}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.flow_of_public_services}
        keys={{ x_axis: xAxis.flow_of_public_services }}
        keyMapper={valueMap.flow_of_public_services}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.regular_services}
        keys={{ x_axis: xAxis.regular_services }}
        keyMapper={valueMap.regular_services}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.special_arrangements}
        keys={{ x_axis: xAxis.special_arrangements }}
        keyMapper={valueMap.special_arrangements}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.service_recipients_treatment}
        keys={{ x_axis: xAxis.service_recipients_treatment }}
        keyMapper={valueMap.service_recipients_treatment}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.information_on_public_importance}
        keys={{ x_axis: xAxis.information_on_public_importance }}
        keyMapper={valueMap.information_on_public_importance}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.ward_office_service_quality}
        keys={{ x_axis: xAxis.ward_office_service_quality }}
        keyMapper={valueMap.ward_office_service_quality}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.ward_office_communication}
        keys={{ x_axis: xAxis.ward_office_communication }}
        keyMapper={valueMap.ward_office_communication}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.hearings}
        keys={{ x_axis: xAxis.hearings }}
        keyMapper={valueMap.hearings}
      />
    </div>
  );
};

export default WardCharts;
