export const labelMap = {
  name: 'group_bg6tj31/__057',
  department: '__004',
  minimum_infrastructure: 'group_bg6tj31/group_eu82n27/__059',
  immunization: 'group_bg6tj31/group_eb4cl04/__061',
  nutrition_program: 'group_bg6tj31/group_yr5uy23/__063',
  family_planning_service: 'group_bg6tj31/group_zp0fi42/__067',
  effective_regular_checkups: 'group_bg6tj31/group_zn6rq96/__072',
  free_medicine_information: 'group_bg6tj31/group_ul6gg44/__076',
  patients_treatment: 'group_bg6tj31/group_xt2gn67/__080',
  requests_respone: 'group_bg6tj31/group_xt2gn67/__081',
};

export const valueMap = {
  minimum_infrastructure: {
    _: 'छ',
    __1: 'छैन',
    __: 'थाहा छैन',
  },
  immunization: {
    ____: 'सबैको पहुँचमा छ',
    _____1: 'सबैको पहुँचमा छैन',
    __: 'थाहा छैन',
  },
  department: {
    _: 'पालीका',
    __: 'वडा कार्यालय',
    ___1: 'स्वास्थ्य चौकी/अस्पताल',
    ___2: 'विद्यालय (शिक्षा)',
  },
  nutrition_program: {
    ____: 'सबैको पहुँचमा छ',
    _____1: 'सबैको पहुँचमा छैन',
    __: 'थाहा छैन',
  },
  family_planning_service: {
    ____: 'सबैको पहुँचमा छ',
    _____1: 'सबैको पहुँचमा छैन',
    __: 'थाहा छैन',
  },
  effective_regular_checkups: {
    ____: 'धेरै प्रभावकारी छ',
    _____1: 'आंशिक प्रभावकारी छ',
    __: 'प्रभावकारी छैन',
    ___1: 'थाहा छैन',
  },
  free_medicine_information: {
    __: 'धेरै सहज',
    ___1: 'आंशिक सहज',
    ___2: 'सहज छैन',
    ___3: 'थाहा छैन',
  },
  patients_treatment: {
    _: 'गर्छन',
    __1: 'गर्दैनन',
    __: 'भन्न चाहन्न',
  },
  requests_respone: {
    __: 'ध्यान दिन्छन',
    ___1: 'बेवास्ता गर्छन',
    _: 'झर्किन्छन',
    ___2: 'भन्न चाहन्न',
  },
};

export const xAxis = {
  name: 'स्वास्थ्य चौकी/अस्पताल को नाम',
  minimum_infrastructure:
    'स्वास्थ्य चौकि/अस्पतालले सेवा प्रदान गर्न आवश्यक न्यूनतम पूर्वाधार (भवन, कक्ष, बेड, उपकरण, प्रयोगशाला, शौचालय, पिउने पानी आदि) को ब्यवश्थापन गरेको छ?',
  immunization:
    'स्वास्थ्य चौकि/अस्पतालले प्रदानगर्ने खोप सेवा के सवैको पहुँचमा छ?',
  nutrition_program:
    'स्वास्थ्य चौकि/अस्पतालले प्रदानगर्ने पोषण कार्यक्रम के सवै लक्षित समुहको पहुँचमा छ?',
  family_planning_service:
    'स्वास्थ्य चौकि/अस्पतालले प्रदानगर्ने परिवार नियोजन सेवा के सबैको पहुँचमा छ?',
  effective_regular_checkups:
    'िरामी जाँचकालागी नियमित रुपमा गाउँघर क्लिनिक, घुम्ति शिविर तथा आकस्मिक सेवा कत्तिको प्रभावकारी पाउनु भएको छ?',
  free_medicine_information:
    'स्वास्थ्य चौकि/अस्पतालले प्रदानगर्ने निशुल्क औषधि वारे आवश्यक जानकारी कत्तिको सहज रुपमा प्राप्त हुने गरेको छ?',
  patients_treatment:
    'के स्वास्थ्यकर्मिहरु विरामी तथा विरामीका आफन्तहरुसँग शिष्ट र सभ्य ब्यवहार गर्छन?',
  requests_respone:
    'विरामी वा विरामीका आफन्तहरुले कुनै जानकारी मागे, सुझाव दिए वा गुनासो गरे के स्वास्थ्यकर्मिहरुले त्यसलाई कसरी लिन्छन?',
};
