import QuestionAnswer from './QuestionAnswer.json';
import moment from 'moment';

class Helper {
  mappingAnswer() {
    const filterFunction = prop => {
      let filteredData = {};
      QuestionAnswer.choices
        .filter(data => {
          return data.list_name === prop;
        })
        .map(data => {
          filteredData[data.name] = data.label;
        });
      return filteredData;
    };

    return QuestionAnswer.survey
      .filter(data => {
        return data.type.includes('select');
      })
      .map(data => {
        return { [data.name]: filterFunction(data.type.split(' ')[1]) };
      })
      .reduce(function(result, item) {
        var key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
  }

  mappingQuestion(key) {
    return QuestionAnswer.survey
      .filter(data => {
        return data.name !== '__version__' && data['$autoname'] === key;
      })
      .map(result => {
        return result.label;
      });
  }

  currentWardPoiData(currentWard, wardData) {
    return wardData.filter(data => {
      return data.ward === currentWard.toString();
    });
  }

  formattext(text) {
    return text.toLowerCase().replace(/ /g, '');
  }

  poiType() {
    return [
      {
        text: 'पर्यटकीय स्थल',
        value: '1',
      },
      {
        text: 'शैक्षिक संस्था',
        value: '2',
      },
      {
        text: 'गापा / नपाका हालका भवन',
        value: '3',
      },
      {
        text: 'व्यापारिक केन्द्र',
        value: '4',
      },
      {
        text: 'स्वास्थ्य सेवा',
        value: '5',
      },
      {
        text: 'वैंक तथा वित्तीय संस्था',
        value: '6',
      },
      {
        text: 'धार्मिक स्थल',
        value: '7',
      },
      {
        text: 'वसपार्क वा वस स्टेशन',
        value: '8',
      },
      {
        text: 'राष्ट्रिय तथा अन्तराष्ट्रिय गैरसरकारी संघसंस्था',
        value: '9',
      },
      {
        text: 'सुरक्षा ईकाई',
        value: '10',
      },
      {
        text: 'एफ.एम / रेडियो स्टेशन',
        value: '11',
      },
    ];
  }

  poiMapping() {
    return {
      'पर्यटकीय स्थल': '1',
      'शैक्षिक संस्था': '2',
      'गापा / नपाका हालका भवन': '3',
      'व्यापारिक केन्द्र': '4',
      'स्वास्थ्य सेवा': '5',
      'वैंक तथा वित्तीय संस्था': '6',
      'धार्मिक स्थल': '7',
      'वसपार्क वा वस स्टेशन': '8',
      'राष्ट्रिय तथा अन्तराष्ट्रिय गैरसरकारी संघसंस्था': '9',
      'सुरक्षा ईकाई': '10',
      'एफ.एम / रेडियो स्टेशन': '11',
    };
  }

  numberToNepali(number) {
    let numbers = {
      '0': '०',
      '०': '०',
      '1': '१',
      '१': '१',
      '2': '२',
      '२': '२',
      '3': '३',
      '३': '३',
      '4': '४',
      '४': '४',
      '5': '५',
      '५': '५',
      '6': '६',
      '६': '६',
      '7': '७',
      '७': '७',
      '8': '८',
      '८': '८',
      '9': '९',
      '९': '९',
      o: '०',
    };
    let numberArray = number.toString().split('');
    let nepaliNumber = numberArray.map(value => {
      return typeof numbers[value] === 'undefined' ? value : numbers[value];
    });
    return nepaliNumber.join('');
  }

  isEmpty(number) {
    if (number === '') {
      return 'N/A';
    }
    if (isNaN(parseInt(number, 10))) {
      return number;
    }
    return this.numberToNepali(number);
  }

  getBeginGroup(data) {
    return Object.keys(data).filter(data => {
      return data.includes('begin');
    });
  }

  googleMapStyle() {
    return [
      {
        stylers: [
          {
            hue: '#007fff',
          },
          {
            saturation: 89,
          },
        ],
      },
      {
        featureType: 'water',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];
  }

  formatBudget(number) {
    let nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number);
  }

  formatNumber(number) {
    let characters = number.toString().split('');
    characters.map((value, index) => {
      if (value === '"' || value === ',' || value === '/' || value === '-') {
        characters.splice(index, 1);
      }
    });
    return characters.join('');
  }

  formatWard(ward) {
    if (!isNaN(parseInt(ward, 10))) {
      return parseInt(ward, 10);
    } else if (ward === 'विविध' || ward === 'सबै') {
      return 9999;
    } else {
      return parseInt(this.numberToEnglish(ward), 10);
    }
  }

  numberToEnglish(number) {
    let numbers = {
      '०': '0',
      '१': '1',
      '२': '2',
      '३': '3',
      '४': '4',
      '५': '5',
      '६': '6',
      '७': '7',
      '८': '8',
      '९': '9',
      ' ': ' ',
      '.': '.',
      '-': '-',
      '/': '/',
      ',': ',',
    };

    const numberArray = number.toString().split('');
    const englishNumber = numberArray.map(value => {
      return numbers[value];
    });
    return englishNumber.join('');
  }

  checkTypeOfNumber(contactNumber) {
    if (!isNaN(parseInt(contactNumber, 10))) {
      return this.numberToNepali(contactNumber);
    } else {
      return contactNumber;
    }
  }

  contactWithSubmission(data) {
    return `<div class="contact">
        <span class="normal contact-title">सम्पर्क</span>
        <div> ${data['contact_person'] ? data['contact_person'] : '-'} (${
      data['contact_number']
        ? this.checkTypeOfNumber(data['contact_number'])
        : '-'
    })</div>
    </div>
    <div class="submission-time">पेश गरिएको समय : ${moment(
      data._submission_time
    )
      .locale('ne')
      .format('LLLL')}

      </div>
     
      `;
  }

  remarks(data) {
    return `<div class="normal ${data['remark'] ? '' : 'hide'}">${
      this.mappingQuestion('remark')[0]
    } : ${data['remark']}</div>`;
  }

  getParameterByName(name) {
    let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  errorMapping(mapData, type, key) {
    if (this.getBeginGroup(mapData)[0]) {
      const beginGroup = this.getBeginGroup(mapData)[0].split('/')[0];
      if (type === 'numberToNepali') {
        return `${this.mappingQuestion(key)[0]} : ${
          mapData[`${beginGroup}/${key}`]
            ? this.numberToNepali(mapData[`${beginGroup}/${key}`])
            : '-'
        }`;
      }
      if (type === 'mappingAnswer') {
        return `${this.mappingQuestion(key)[0]} : ${
          mapData[`${beginGroup}/${key}`] &&
          mapData[`${beginGroup}/${key}`] !== '_'
            ? this.mappingAnswer()[key][mapData[`${beginGroup}/${key}`]]
            : '-'
        }`;
      }
      if (type !== 'numberToNepali' || type !== 'mappingAnswer') {
        return `${this.mappingQuestion(key)[0]} : ${
          mapData[`${beginGroup}/${key}`]
            ? mapData[`${beginGroup}/${key}`]
            : '-'
        }`;
      }
    }
  }

  // conversion string in DD to DMS.
  ddToDms(lat, lng) {
    var lat = lat;
    var lng = lng;
    var latResult, lngResult, dmsResult;

    lat = parseFloat(lat);
    lng = parseFloat(lng);

    latResult = lat >= 0 ? 'N' : 'S';

    // Call to getDms(lat) function for the coordinates of Latitude in DMS.
    // The result is stored in latResult variable.
    latResult += this.getDms(lat);

    lngResult = lng >= 0 ? 'E' : 'W';

    // Call to getDms(lng) function for the coordinates of Longitude in DMS.
    // The result is stored in lngResult variable.
    lngResult += this.getDms(lng);

    // Joining both variables and separate them with a space.
    dmsResult = latResult + ' ' + lngResult;

    // Return the resultant string
    return dmsResult;
  }

  getDms(val) {
    var valDeg, valMin, valSec, result;

    val = Math.abs(val);

    valDeg = Math.floor(val);
    result = valDeg + 'º';

    valMin = Math.floor((val - valDeg) * 60);
    result += valMin + "'";

    valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
    result += valSec + '"';

    return result;
  }

  poiDetailHead(data) {
    const DMSCoordinates = this.ddToDms(
      data._geolocation[0],
      data._geolocation[1]
    );
    return `<div class="poi-detail-title pd-bottom-28">
                 <div class="heading">
                    <span class="main-title">${data.name_nep}</span>
                    <span class="ward-info">${
                      this.mappingQuestion('ward')[0]
                    } ${this.numberToNepali(data.ward)}${
      data.tole ? `, ${data.tole}` : ''
    }</span>
                </div>
               <div class="geo-location">
                  ${this.numberToNepali(DMSCoordinates)}
                  </div>
            </div>`;
  }

  localGovernment() {
    return [
      'sanfebagar',
      'masta',
      'konjyosom',
      'bagmati',
      'barhabise',
      'sunkoshi',
      'amargadhi',
      'nawadurga',
      'dhangadhi',
      'kailari',
      'bannigadhijayagadh',
      'jayaprithivi',
      'jugal',
      'ajayameru',
      'mellekh',
      'mellekhmun',
      'chaurpati',
      'khaptadchhanna',
      'parshuram',
      'durgathali',
      'gauriganga',
    ];
  }

  filteredGovernment(host) {
    const localGovernments = this.localGovernment().filter(
      v => v[0] === host[0]
    );
    return localGovernments.length ? localGovernments : this.localGovernment();
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  showCovidBlock(id) {
    let remove = [14, 15, 16, 17, 18, 19, 20, 21];
    return !remove.includes(id);
  }
}

export default new Helper();
