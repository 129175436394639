import React from 'react';
import styled from 'styled-components';
import FilterIcon from '../../../../assets/images/group.svg';

const Burger = props => {
	return (
		<Content>
			<a title="Menu" className={props.isOpen ? 'hamburger-icon active' : 'hamburger-icon'}>
				<img src={FilterIcon} alt="filter" />
			</a>
		</Content>
	);
};

export default Burger;

const Content = styled.div`
    display: flex;
    align-items: center;
`;
