import React, { useEffect, useState } from 'react';
import { labelMap, valueMap, xAxis } from '../config/data-maps/school';
import ChartWrapper from '../../MunicipalityExitPoll/components/ChartWrapper';
import BarChart from '../../MunicipalityExitPoll/components/BarChart';
import { Loader } from '../../../components/Loader';
import PieChart from '../../MunicipalityExitPoll/components/PieChart';

const reduceStat = (data, key) => {
  let stat = data.reduce((acc, current) => {
    const value = current[labelMap[key]];
    if (value) {
      if (valueMap.hasOwnProperty(key)) {
        const label = valueMap[key][value];
        acc[label] = acc[label] ? acc[label] + 1 : 1;
      } else {
        acc[value] = acc[value] ? acc[value] + 1 : 1;
      }
    }
    return acc;
  }, {});

  let initialStat = {};
  if (valueMap.hasOwnProperty(key)) {
    Object.keys(valueMap[key]).forEach(
      label => (initialStat[valueMap[key][label]] = 0)
    );
  }
  stat = { ...initialStat, ...stat };

  return Object.keys(stat).map(function(key) {
    return { key: key, value: stat[key] };
  });
};

const generateStats = data => {
  return {
    name: reduceStat(data, 'name'),
    department: reduceStat(data, 'department'),
    public_information: reduceStat(data, 'public_information'),
    minimum_infrastructure: reduceStat(data, 'minimum_infrastructure'),
    learning_activities: reduceStat(data, 'learning_activities'),
    consultation: reduceStat(data, 'consultation'),
    physical_mental_moral_development: reduceStat(
      data,
      'physical_mental_moral_development'
    ),
    scholarships_information: reduceStat(data, 'scholarships_information'),
    teachers_response: reduceStat(data, 'teachers_response'),
  };
};

const SchoolCharts = ({ data }) => {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    const generatedStat = generateStats(data);
    setStats(generatedStat);
  }, [data]);

  if (!stats) {
    return (
      <div className="stats-wrapper">
        <Loader />
      </div>
    );
  }

  if (stats.department[3].value === 0) {
    return (
      <div className="content__empty" style={{ marginTop: '20px' }}>
        <p>No Data was found.</p>
      </div>
    );
  }

  return (
    <div className="stats-wrapper">
      <ChartWrapper
        chartType={BarChart}
        data={stats.name}
        keys={{ x_axis: xAxis.name }}
        keyMapper={valueMap.name}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.public_information}
        keys={{ x_axis: xAxis.public_information }}
        keyMapper={valueMap.public_information}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.minimum_infrastructure}
        keys={{ x_axis: xAxis.minimum_infrastructure }}
        keyMapper={valueMap.minimum_infrastructure}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.learning_activities}
        keys={{ x_axis: xAxis.learning_activities }}
        keyMapper={valueMap.learning_activities}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.consultation}
        keys={{ x_axis: xAxis.consultation }}
        keyMapper={valueMap.consultation}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.physical_mental_moral_development}
        keys={{ x_axis: xAxis.physical_mental_moral_development }}
        keyMapper={valueMap.physical_mental_moral_development}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.scholarships_information}
        keys={{ x_axis: xAxis.scholarships_information }}
        keyMapper={valueMap.scholarships_information}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.teachers_response}
        keys={{ x_axis: xAxis.teachers_response }}
        keyMapper={valueMap.teachers_response}
      />
    </div>
  );
};

export default SchoolCharts;
