import React from 'react';
import { render } from 'react-dom';
import './index.css';
import ErrorBoundary from './error-boundary';
import RouterComponent from './routes';
import { ThemeProvider } from 'styled-components';
import theme from '../src/app/utils/theme';
import GlobalStyle from '../src/app/components/styled/global';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
require('react-select2-wrapper/css/select2.css');

render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <GlobalStyle />
      <RouterComponent />
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);
