/* Imports */
import React from 'react';
const TableChart = ({ data, x_axis, y_axis }) => {
  return (
    <div className="table-wrap">
      <table>
        <thead>
        <tr>
          <th>SN</th>
          <th>{x_axis}</th>
          <th>{y_axis}</th>
        </tr>
        </thead>
        <tbody>
        {
          data.length && data.map((d, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{d.key}</td>
              <td className="value">{d.value}</td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
};

export default TableChart;
