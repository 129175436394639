import React, { useEffect, useState } from 'react';
import { labelMap, valueMap } from '../config/data-maps/palika';
import ChartWrapper from '../../MunicipalityExitPoll/components/ChartWrapper';
import BarChart from '../../MunicipalityExitPoll/components/BarChart';
import { Loader } from '../../../components/Loader';
import PieChart from '../../MunicipalityExitPoll/components/PieChart';

const reduceStat = (data, key) => {
  let stat = data.reduce((acc, current) => {
    const value = current[labelMap[key]];
    if (value) {
      if (valueMap.hasOwnProperty(key)) {
        const label = valueMap[key][value];
        acc[label] = acc[label] ? acc[label] + 1 : 1;
      } else {
        acc[value] = acc[value] ? acc[value] + 1 : 1;
      }
    }
    return acc;
  }, {});

  let initialStat = {};
  if (valueMap.hasOwnProperty(key)) {
    Object.keys(valueMap[key]).forEach(
      label => (initialStat[valueMap[key][label]] = 0)
    );
  }
  stat = { ...initialStat, ...stat };
  return Object.keys(stat).map(function(key) {
    return { key: key, value: stat[key] };
  });
};

const generateStats = data => {
  return {
    gender: reduceStat(data, 'gender'),
    department: reduceStat(data, 'department'),
    municipality_satisfaction: reduceStat(data, 'municipality_satisfaction'),
    special_arrangements: reduceStat(data, 'special_arrangements'),
    services_provided: reduceStat(data, 'services_provided'),
    intermediaries_support: reduceStat(data, 'intermediaries_support'),
    public_services_procedure: reduceStat(data, 'public_services_procedure'),
    citizen_charter_usefulness: reduceStat(data, 'citizen_charter_usefulness'),
    interaction_on_public_concern: reduceStat(
      data,
      'interaction_on_public_concern'
    ),
    use_of_technology: reduceStat(data, 'use_of_technology'),
  };
};

const MunicipalityCharts = ({ data }) => {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    const generatedStat = generateStats(data);
    setStats(generatedStat);
  }, [data]);

  if (!stats) {
    return (
      <div className="stats-wrapper">
        <Loader />
      </div>
    );
  }
  if (stats.department[0].value === 0) {
    return (
      <div className="content__empty" style={{ marginTop: '20px' }}>
        <p>No Data was found.</p>
      </div>
    );
  }
  return (
    <div className="stats-wrapper">
      <ChartWrapper
        chartType={BarChart}
        data={stats.department}
        keys={{
          x_axis: 'निकाय',
        }}
        keyMapper={valueMap.department}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.municipality_satisfaction}
        keys={{
          x_axis:
            'आफ्नो स्थानिय सरकार (पालीका) वाट नागरिकले प्राप्त गर्ने सेवाप्रती समग्रमा तपाँई कत्तिको सन्तुष्ट हुनुहुन्छ?',
        }}
        keyMapper={valueMap.municipality_satisfaction}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.special_arrangements}
        keys={{
          x_axis:
            'पालीकाले महिला, वालबालबालीका, जेष्ठ नागरिक, अपाङ्गता भएका ब्यक्तिहरु, दलित, जनजाती, विपन्न तथा अल्पसंख्यक (लक्षित) नागरिकहरुलाई सेवा प्रदान गर्न विशेष ब्यवश्था वा पहल गरेको थाहा छ?',
        }}
        keyMapper={valueMap.special_arrangements}
      />
      <ChartWrapper
        chartType={BarChart}
        data={stats.services_provided}
        keys={{
          x_axis:
            'पालीकावाट प्राप्त हुने सेवाहरु, सेवाप्राप्त गर्न पुरागर्नुपर्ने शर्त तथा प्रकृया, सेवा प्रदान गर्ने अधिकारी, सेवाप्राप्त गर्न लाग्ने समय आदिका वारेका तपाँई कत्तीको कत्तीको जानकार हुनुहुन्छ?',
        }}
        keyMapper={valueMap.services_provided}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.intermediaries_support}
        keys={{
          x_axis: 'पालीकामा सेवा लिन आउँदा के विचौलियाहरुको सहयोग लिनुपरेको छ?',
        }}
        keyMapper={valueMap.intermediaries_support}
      />
      <ChartWrapper
        chartType={PieChart}
        data={stats.gender}
        keys={{ x_axis: 'सेवाग्राहीको लिंग' }}
        keyMapper={valueMap.gender}
      />

      <ChartWrapper
        chartType={PieChart}
        data={stats.public_services_procedure}
        keys={{
          x_axis:
            'पालीकाले स्थानिय रुपमा प्रदान गर्ने सार्वजनिक सेवा (शिक्षा, स्वास्थ्य, सरसफाई, कृषि, पशु, खानेपानी, सिंचाई, सामाजिक सुरक्षा आदि) सम्बन्धि स्थानिय कार्यविधि बारे तपाँईलाई जानकारी छ?',
        }}
        keyMapper={valueMap.public_services_procedure}
      />

      <ChartWrapper
        chartType={BarChart}
        data={stats.citizen_charter_usefulness}
        keys={{
          x_axis:
            'पालीकाले तयार पारेको नागरिक वडापत्र तपाँईकालागी कत्तिको उपयोगी छ?',
        }}
        keyMapper={valueMap.citizen_charter_usefulness}
      />

      <ChartWrapper
        chartType={BarChart}
        data={stats.required_information_explanation}
        keys={{
          x_axis:
            'पालीकाले तोकेको सुचना अधिकारीले तपाँईले मागेको सुचना दिन कत्तिको सहयोग गर्ने गरेको पाउनु हुन्छ?',
        }}
        keyMapper={valueMap.required_information_explanation}
      />

      <ChartWrapper
        chartType={BarChart}
        data={stats.interaction_on_public_concern}
        keys={{
          x_axis:
            'पालीकाले सार्वजनिक सरोकारका विषयमा नागरिकहरुसंग संवाद वा अन्तरक्रिया गर्ने गरेको छ?',
        }}
        keyMapper={valueMap.interaction_on_public_concern}
      />

      <ChartWrapper
        chartType={BarChart}
        data={stats.use_of_technology}
        keys={{
          x_axis:
            'पालीकाले सार्वजनिक सुचनाहरु संकलन, अभिलेखीकरण र प्रसारणकालागी गरेको सुचना प्रविधिको प्रयोग कत्तिको प्रभावकारी पाउनु भएको छ?',
        }}
        keyMapper={valueMap.use_of_technology}
      />
    </div>

  );
};

export default MunicipalityCharts;
